import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector, useAppDispatch, RootState } from "store/store";
import { setUiErrorScreen } from "store/app.slice";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { ErrorTypes, firebaseCollections, ScreenShareStatus, SessionScope, StreamingProvider } from "globals/enums";
import { useNavigate } from "react-router";
import { handleError } from "helpers/sentry";
import { AlertName } from "helpers/alert-type";
import { alertHelper } from "helpers/alert-helper";
import { AlertLogConfig } from "services/session-token";
import { RecordingService } from "globals/interfaces";
import { TwilioService } from "services/twilio";
import { useStreamParticipantMutation } from "services/stream";
import { MediaContext } from "context/MediaContext";
import { LocalTrackPublication as TwilioLocalTrackPublication } from "twilio-video";
import { LocalTrackPublication as LiveKitLocalTrackPublication } from "livekit-client";
import { detectMob } from "helpers/browser-helper";

import { getDatabase, ref, update } from "firebase/database";

const useRecorder = () => {
  const configuration = useAppSelector((state) => state.app.configuration);
  const session = useAppSelector((state) => state.app.session);
  const [streamRoom, setStreamRoom] = useState(null);
  const { screenShareStatus } = useAppSelector((state) => state.screenShare);
  const reconnectionCount = useAppSelector(state => state.app.reconnectionCount);
  const publishedScreenTrackRef = useRef<
    TwilioLocalTrackPublication | LiveKitLocalTrackPublication | null
  >(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isVideoStreamingEnabled = useAppSelector(
    (state: RootState) => state.app.isVideoStreamingEnabled
  );
  const isScreenRecordingEnabled = useAppSelector(
    (state: RootState) => state.app.isScreenRecordingEnabled
  );

  const [createStream] = useStreamParticipantMutation();
  const { mediaStream, displayStream } = useContext(MediaContext);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const showDisconnection = useRef(true);

  function getProvider(): RecordingService {
    if (configuration.streaming_provider === StreamingProvider.Livekit) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const LivekitService = require("services/livekit").LivekitService;
      return new LivekitService();
    } else if (configuration.streaming_provider === StreamingProvider.Twilio) {
      return new TwilioService();
    }
  }

  function goToErrorScreen() {
    dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.StreamingReconnection }));
    navigate(getEndpoint(ProctoringRoute.Error));
  }

  const recordingService = useMemo(() => {
    return getProvider();
  }, [configuration.streaming_provider]);
  const participantGroupId = useAppSelector((state) => state.app.channelData.participant_group_id);

  async function startStreaming() {
    try {
      const participant_group_id = participantGroupId;
      const stream = await createStream({ participant_group_id }).unwrap();
      recordingService.connect({
        tracks: isVideoStreamingEnabled ? mediaStream.getTracks() : [],
        streamingToken: stream.token,
        participantGroupId: participant_group_id,
        onDisconnect: () => {
          handleError("Streaming disconnected");
          if (showDisconnection.current) {
            goToErrorScreen();
          }
        },
        trackPublished: (room) => {
          setStreamRoom(room);
          showDisconnection.current = true;
          alertHelper(alertConfig).raiseAlert(AlertName.RecordingStarted, dispatch);
        },
        onTrackPublishFailed: (e) => {
          handleError(e);
          goToErrorScreen();
        },
        onNetworkQualityChange: (goodBandwidth) => {
          if (goodBandwidth) {
            alertHelper(alertConfig).raiseAlert(AlertName.CandidateGoodBandwidth, dispatch);
          } else {
            alertHelper(alertConfig).raiseAlert(AlertName.CandidateLowBandwidth, dispatch);
          }
        },
        onConnectionFailure: (e) => {
          handleError("Streaming connection failure");
          if (showDisconnection.current) {
            handleError(e);
            goToErrorScreen();
          }
        },
        onReconnecting: () => {
          alertHelper(alertConfig).raiseAlert(
            AlertName.VideoStreamReconnect,
            dispatch,
            "Reconnecting"
          );
        },
        onReconnected: () => {
          alertHelper(alertConfig).raiseAlert(
            AlertName.VideoStreamReconnect,
            dispatch,
            "Reconnected"
          );
        },
        onAudioVideoTrackPublished: async(trackInfo, streamingProvider) => {
          const roomParticipantRef = ref(getDatabase(), `${firebaseCollections.RoomParticipant}/${streamingProvider}_${session?.session_id}/tracks/${trackInfo?.participantSid}`);
          await update(roomParticipantRef, {
              ...trackInfo.tracks
          });
        }
      });
    } catch (e: any) {
      handleError("Streaming exception in useRecorder");
      handleError(e);
      goToErrorScreen();
    }
  }

  const stopStreaming = () => {
    showDisconnection.current = false;
    setStreamRoom(null);
    recordingService.disconnect();
  };
  const isCandidateAuthSession = session?.session_type === SessionScope.CandidateAuth;

  useEffect(() => {
    if (!recordingService) {
      return;
    }
    if ((isVideoStreamingEnabled || isScreenRecordingEnabled) && !isCandidateAuthSession) {
      startStreaming();
    } else if (streamRoom) {
      stopStreaming();
    }
  }, [
    recordingService,
    isVideoStreamingEnabled || isScreenRecordingEnabled,
    isCandidateAuthSession,
    reconnectionCount
  ]);

  useEffect(() => {
    if (
      recordingService &&
      isVideoStreamingEnabled &&
      streamRoom &&
      mediaStream &&
      mediaStream.active
    ) {
      recordingService.flipVideo(mediaStream.getTracks());
    }
  }, [mediaStream, isVideoStreamingEnabled]);

  const isNotCandidateAuth = session?.session_type !== SessionScope.CandidateAuth;
  const isNotPlatformStreaming =
    configuration.streaming_provider !== StreamingProvider.PlatformStreaming;

  const screenSharingEnabled = useMemo(() => {
    return isNotCandidateAuth && isScreenRecordingEnabled && isNotPlatformStreaming;
  }, [isNotCandidateAuth, isScreenRecordingEnabled, isNotPlatformStreaming]);

  const startScreenRecording = async () => {
    const screenTrack = recordingService.createLocalVideoTrack(
      displayStream.getTracks()[0],
      "screenshare"
    );
    if (configuration.screen_recording) {
      const publishedScreenTrack = await recordingService.publishTrack(screenTrack, "screenshare");
      publishedScreenTrackRef.current = publishedScreenTrack;
    }
  };

  const stopScreenRecording = () => {
    if (publishedScreenTrackRef.current) {
      showDisconnection.current = false;
      recordingService.unpublishTrack(publishedScreenTrackRef.current);
      publishedScreenTrackRef.current = null;
    }
  };

  const mobileDevice = useMemo(() => {
    return detectMob() && configuration.is_mobile_supported;
  }, []);

  useEffect(() => {
    if (!streamRoom || !screenSharingEnabled || !recordingService || mobileDevice) {
      return;
    }
    if (streamRoom && screenShareStatus === ScreenShareStatus.ScreenShareStarted) {
      startScreenRecording();
      return;
    }
    if (screenShareStatus === ScreenShareStatus.ScreenShareStopped) {
      stopScreenRecording();
      return;
    }
  }, [screenShareStatus, streamRoom, screenSharingEnabled]);

  return [recordingService];
};

export default useRecorder;

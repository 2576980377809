import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAppSelector, useAppDispatch } from "store/store";
import { removeToastedAlert } from "store/app.slice";
import { FormattedMessage } from "react-intl";
import { AlertDisplayType } from "helpers/alert-type";
import "react-toastify/dist/ReactToastify.css";

interface ToastAlert {
  description: string;
  name: string;
}

export const ToastContent = (props: ToastAlert) => {
  return (
    <div role="document" aria-label="Notification details">
      <p>{<FormattedMessage id={props.name} />}</p>
      <p>{<FormattedMessage id={props.description} />}</p>
    </div>
  );
};

const Toastcontainer = () => {
  const dispatch = useAppDispatch();
  const notificationAlerts = useAppSelector((state) => state.app.notificationAlerts);

  useEffect(() => {
    if (notificationAlerts.length) {
      const currentAlert = notificationAlerts[0];
      const content = (
        <div
          role={currentAlert.displayType === AlertDisplayType.Info ? "status" : "alert"}
          aria-live={currentAlert.displayType === AlertDisplayType.Info ? "polite" : "assertive"}
        >
          <ToastContent name={currentAlert.alertName} description={currentAlert.description} />
        </div>
      );

      if (currentAlert.displayType === AlertDisplayType.Info) {
        toast.info(content);
      } else {
        toast.error(content);
      }

      dispatch(removeToastedAlert());
    }
  }, [notificationAlerts, dispatch]);

  return (
    <ToastContainer
      icon={true}
      role="region"
      aria-label="Toast notifications"
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnFocusLoss={false}
      pauseOnHover={true}
      draggable={false}
      theme="colored"
    />
  );
};

export default Toastcontainer;

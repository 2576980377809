import { getDatabase, ref, onValue } from "firebase/database";
import { firebaseCollections } from "globals/enums";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { useEffect, useRef } from "react";
import { AlertLogConfig } from "services/session-token";
import { useAppSelector, useAppDispatch } from "store/store";

const useExtendSession = () => {
  const session = useAppSelector((state) => state.app.session);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const dispatch = useAppDispatch();

  const processedUpdates = useRef<Set<string>>(new Set());

  const handleSessionExtension = (data) => {
    // TODO: Integrate API call here
    alertHelper(alertConfig).raiseAlert(
      AlertName.ProctoringSessionExtended,
      dispatch,
      "",
      JSON.stringify({
        extend_time: data.extendTimeInMinutes,
        extend_reason: data.extendReason
      })
    );
  };

  useEffect(() => {
    if (!session.session_id) return;

    const extendRef = ref(
      getDatabase(),
      `${firebaseCollections.SessionV2}/${session.session_id}/extend`
    );

    const unsubscribe = onValue(extendRef, (snapshot) => {
      const data = snapshot.val();
      if (data?.updateId && !processedUpdates.current.has(data.updateId)) {
        console.log("Processing session extension:", data);
        processedUpdates.current.add(data.updateId);
        handleSessionExtension(data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [session.session_id]);

  return null;
};

export default useExtendSession;

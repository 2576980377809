import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Content: React.FC<Props> = ({ children }) => {
  return (
    <div
      className="tvp-h-auto tvp-bg-white tvp-flex-auto tvp-flex tvp-flex-col tvp-px-2 tvp-justify-around tvp-content"
      role="main"
    >
      {children}
    </div>
  );
};

export default Content;

/* eslint-disable */
import { SessionScope } from "globals/enums";
import { InitScriptConfiguration, ClientConfiguration } from "globals/interfaces";
import { store } from "store/store";

export interface InitialDataPayload {
  proctor_token: string;
  attendee_external_id: string;
  attendee?: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    additional_properties?: {};
  };
  session: {
    external_id: string;
    scope: SessionScope;
    title: string | undefined;
    accommodations?: {};
  };
  configuration: {};
  previewStyle: string;
  clientConfiguration: ClientConfiguration;
}

export const getInitialData = (
  initScript: any | undefined,
  initialState: InitScriptConfiguration
): InitialDataPayload => {
  let initialData: InitialDataPayload = {
    proctor_token: initialState.UID,
    attendee_external_id: initialState.profile_id,
    session: {
      external_id: initialState.session_id,
      scope: initialState.session_type as SessionScope,
      title: initialState.session_title
    },
    configuration: {},
    clientConfiguration: {
      additionalInstruction: null,
      referenceLinks: [],
      enforceTSB: false,
      redirectURL: "",
      blacklistedSoftwaresWindows: "",
      blacklistedSoftwaresMac: "",
      whitelistedSoftwaresWindows: "",
      whitelistedSoftwaresMac: "",
      minimizeOption: "false",
      isScreenProtectionEnabled: "false",
      restrictedURLDomains: ""
    },
    previewStyle: ""
  };
  if (initScript) {
    initialData = {
      proctor_token: initScript?.[1],
      attendee_external_id: initScript[2]?.profileId,
      attendee: {
        first_name: initScript[2]?.first_name,
        middle_name: initScript[2]?.middle_name,
        last_name: initScript[2]?.last_name,
        email: initScript[2]?.email,
        additional_properties: initScript[2]?.additional_properties
      },
      session: {
        external_id: initScript[2]?.session,
        scope: initScript[2]?.session_type || initScript[2]?.sessionType,
        title: initScript[2]?.sessionTitle,
        accommodations: initScript[2]?.accommodations
      },
      previewStyle: initScript[2]?.previewStyle,
      configuration: {},
      clientConfiguration: {
        additionalInstruction: initScript[2]?.additionalInstruction || null,
        referenceLinks: initScript[2]?.referenceLinks || [],
        enforceTSB: initScript[2]?.enforceTSB || false,
        redirectURL: initScript[2]?.redirectURL || window.location.href,
        blacklistedSoftwaresWindows: initScript[2]?.blacklistedSoftwaresWindows || "",
        blacklistedSoftwaresMac: initScript[2]?.blacklistedSoftwaresMac || "",
        whitelistedSoftwaresWindows: initScript[2]?.whitelistedSoftwaresWindows || "",
        whitelistedSoftwaresMac: initScript[2]?.whitelistedSoftwaresMac || "",
        minimizeOption: initScript[2]?.minimizeOption || "false",
        isScreenProtectionEnabled: initScript[2]?.isScreenProtectionEnabled || "false",
        restrictedURLDomains: initScript[2]?.restrictedURLDomains || ""
      }
    };
  }
  return initialData;
};
export const pauseIfOffline = async () => {
  while (!navigator.onLine) {
    // Watch until network is back online
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  return;
};

export const getSystemTime = (): Date => {
  const systemTimeDiff = store.getState().app.systemTimeDiff;
  const currentDate = new Date();

  if (!systemTimeDiff) return currentDate;

  const correctedDateTime = currentDate.getTime() + systemTimeDiff;
  return new Date(correctedDateTime);
};

import React, { useState, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store/store";
import "./dragable.css";
import { GlobalAppState } from "globals/interfaces";
import {
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  LinkIcon,
  VideoCameraIcon
} from "@heroicons/react/24/outline";

import { setProctorCandidateChat, setChatService } from "store/app.slice";
import useChatOptions from "hooks/useChatOptions";
import { ChatSupportOptions } from "globals/enums";

const DraggedDiv = () => {
  const dispatch = useAppDispatch();
  const holderRef = useRef<HTMLDivElement>(null);
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const showLinkAndVideo = useAppSelector((state) => state.app.showLinkAndVideo);
  const referenceLinks = useAppSelector((state) => {
    const referenceLinkValues = state.app.clientConfiguration.referenceLinks as any;
    if (referenceLinkValues) {
      try {
        return JSON.parse(referenceLinkValues);
      } catch (error) {
        return [];
      }
    }
    return [];
  });

  const chatOptions = useChatOptions();

  const [isDragging, setIsDragging] = useState(false);
  const [isVideoHide, setIsVideoHide] = useState(true);

  const dragStartPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const [showChatModal, setShowChatModal] = useState(false);

  const handleChatClick = () => {
    setShowChatModal(!showChatModal);
    setShowModal(false);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    const { clientX, clientY } = event;
    dragStartPosition.current = { x: clientX, y: clientY };
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  React.useEffect(() => {
    if (isDragging) {
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    if (chatOptions?.includes(ChatSupportOptions.ChatWithProctor)) {
      dispatch(setChatService({ chatService: true }));
    }
  }, [chatOptions]);

  const handleVideoShow = () => {
    setIsVideoHide(true);
  };
  const handleVideoClose = () => {
    setIsVideoHide(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleLinkClick = () => {
    setShowModal(!showModal);
    setShowChatModal(false);
  };

  const handleChatWidgetClick = (item) => {
    if (item === ChatSupportOptions.ChatWithProctor) {
      dispatch(setProctorCandidateChat({ showProctorCandidateChat: true }));
    } else {
      window.fcWidget.open();
    }
  };

  useEffect(() => {
    if (holderRef && globalState?.previewStyle?.length) {
      holderRef.current.setAttribute("style", globalState.previewStyle);
    } else {
      holderRef.current.setAttribute("style", "left: 30px; bottom: 20px");
    }
  }, [globalState?.previewStyle]);

  return (
    <>
      <div
        ref={holderRef}
        data-testid="tvp_recorder_holder"
        id="tvp_recorder_holder"
        className="tvp-divDrag tvp-recorder-holder"
        onMouseDown={handleMouseDown}
      >
        <div className="tvp-sharedSreen">
          <div
            data-testid="tvp_recorder_container_holder"
            id="tvp_recorder_container_holder"
            className={isVideoHide ? "tvp-isVisible" : "tvp-isHidden"}
          >
            <div id="tvp_recorder_container" className=""></div>
          </div>

          <div data-testid="sharedIcon" id="sharedIcon" className="tvp-sharedIcon">
            {isVideoHide && showLinkAndVideo && (
              <button
                className="tvp-button-no-border"
                onClick={handleVideoClose}
                aria-label="video-no-show"
              >
                <VideoCameraIcon className="tvp-video-icon" />
              </button>
            )}
            {!isVideoHide && showLinkAndVideo && (
              <button
                className="tvp-button-no-border"
                onClick={handleVideoShow}
                aria-label="video-show"
              >
                <VideoCameraIcon className="tvp-video-icon" />
              </button>
            )}
            {chatOptions?.length ? (
              <button
                onClick={handleChatClick}
                className="tvp-relative tvp-button-no-border"
                aria-label="Chat"
              >
                <ChatBubbleLeftRightIcon className="tvp-chat-icon" />
                {showChatModal && chatOptions?.length && (
                  <div
                    className="tvp-flex tvp-flex-auto tvp-flex-col tvp-justify-start tvp-py-2.5 tvp-px-2 tvp-bg-white tvp-linkModel tvp-absolute"
                    onClick={handleChatClick}
                  >
                    <div className="tvp-flex tvp-flex-col tvp-clr-black tvp-gap-1 tvp-text-slate">
                      {chatOptions.map((item, index) => (
                        <button
                          className="tvp-cursor-pointer tvp-flex tvp-alignCenter tvp-button-no-border"
                          key={index}
                          onClick={() => handleChatWidgetClick(item)}
                        >
                          <span className="tvp-rounded-full tvp-w-7 tvp-h-7 tvp-flex tvp-items-center tvp-border-1.5 tvp-border-black">
                            <ChatBubbleOvalLeftEllipsisIcon className="tvp-w-6 tvp-h-6 tvp-text-black" />
                          </span>
                          <div className="tvp-ml-2 tvp-whitespace-nowrap tvp-font-semibold">
                            {item}
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </button>
            ) : null}
            {referenceLinks?.length && showLinkAndVideo ? (
              <button
                data-testid="referenceLinkIcon"
                onClick={handleLinkClick}
                className="tvp-relative tvp-button-no-border"
                aria-label="Reference Links"
              >
                <LinkIcon className="tvp-external-link-icon" />

                {showModal && (
                  <div
                    className="tvp-flex tvp-flex-auto tvp-flex-col tvp-justify-start tvp-py-2.5 tvp-px-4 tvp-bg-white tvp-linkModel tvp-modalPosition tvp-absolute tvp-overflow-y-auto tvp-max-h-96"
                    onClick={handleLinkClick}
                  >
                    <div className="tvp-flex tvp-flex-col tvp-clr-black tvp-gap-1">
                      {referenceLinks.map((item, index) => (
                        <a
                          className="tvp-leading-6"
                          href={item.url}
                          target="blank"
                          key={item.caption}
                        >
                          <div className="tvp-cursor-pointer tvp-flex">
                            <span className="tvp-bg-blue-300 tvp-text-white tvp-rounded-full tvp-w-6 tvp-h-6 tvp-flex tvp-items-center tvp-justify-center">
                              {index + 1}
                            </span>
                            <span className="tvp-ml-2.5">{item.caption}</span>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DraggedDiv;

import { ErrorTypes } from "globals/enums";
import { getEndpoint, ProctoringRoute } from "routes/route";
import { setIsStreamingDisconnected, setUiErrorScreen } from "store/app.slice";

const handleStreamingDisconnection = async (
  retryCount,
  existingStreamToken,
  startStreaming,
  dispatch,
  navigate,
  reconnectSuccessCount
) => {
  if (retryCount < 2 && reconnectSuccessCount < 6) {
    await new Promise((resolve) => setTimeout(resolve, 30000));
    console.log("Reconnecting Attempt: ", retryCount + 1);
    startStreaming(retryCount + 1, existingStreamToken);
  } else {
    console.log("Retry/Reconnection Limit Exceeded!");
    dispatch(setIsStreamingDisconnected({ isStreamingDisconnected: true }));
    dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.StreamingReconnection }));
    navigate(getEndpoint(ProctoringRoute.Error));
  }
};

export default handleStreamingDisconnection;

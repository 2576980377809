import { useCallback, useEffect, useState } from "react";
import _ from "underscore";
import { chatOpenAudio } from "config/constant";
import MessageAudioBox from "components/Chat/MessageAudioBox";
import MessageList from "components/Chat/MessageList";
import MessageInput from "components/Chat/MessageInput";
import { ChannelState } from "globals/interfaces";
import { useAppSelector } from "store/store";
import UseGetZoomLevel from "hooks/UseGetZoomLevel";
import Closebtn from "assets/img/chatClose.svg";
import Loader from "assets/img/loader_green.gif";
import { initializeChat } from "services/external/chatservice";
type ChatProps = {
  chatOpen?: boolean;
  handleChatOpen: () => void;
  handleChatClose: () => void;
};

const ChatView = (Props: ChatProps) => {
  const chatDetails = useAppSelector((state) => state.app.chat);
  const channelData = useAppSelector((state) => state.app.channelData);
  const configuration = useAppSelector((state) => state.app.configuration);
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const [chatService, setChatService] = useState<any>();
  const { resizeLevel } = UseGetZoomLevel();

  const initializeChatService = async (channelData: ChannelState) => {
    const chat = await initializeChat(chatDetails, channelData, refreshMessages);
    setChatService(chat);
    setChatMessages(await chat?.getMessages());
  };

  const refreshMessages = useCallback(
    (event: any) => {
      const { message } = event;
      if (
        chatMessages?.length ||
        event.type === "message.new" ||
        event.type === "message.updated"
      ) {
        event.type === "message.new" && event?.user?.name === "Proctor" && triggerOpenChat();
        setChatMessages((prev) => {
          let newchatMessages = [...prev];
          newchatMessages = newchatMessages.filter((m) => m.id !== message.id);
          newchatMessages.push(message);
          const sortedData = _.sortBy(newchatMessages, "created_at");
          return sortedData;
        });
        event.type === "message.new" && chatService?.markAsDelivered(event.message);
      }
    },
    [chatService]
  );

  useEffect(() => {
    if (channelData) {
      (async () => {
        await initializeChatService(channelData);
      })();
    }
  }, [channelData]);

  const handleSendMessage = useCallback(
    async (value: string) => {
      const text = value.trim();
      const data = await chatService?.sendMessage(text);
      if (data) {
        refreshMessages(data);
      }
    },
    [chatService]
  );

  const triggerOpenChat = () => {
    playMessageReceivedSound();
    Props.handleChatOpen();
  };

  const playMessageReceivedSound = () => {
    const audio = new Audio(chatOpenAudio);
    audio.play();
  };

  const handleMarkRead = useCallback(async () => {
    await chatService?.markAllMessagesAsRead();
  }, [chatService]);

  useEffect(() => {
    (async () => {
      const messages = await chatService?.getMessages();
      if (chatMessages?.length < messages?.length) {
        setChatMessages(messages);
      }
      if (Props.chatOpen) {
        await chatService?.markAllMessagesAsDelivered();
      }
    })();
  }, [chatService, Props.chatOpen]);

  return (
    <>
      {Props.chatOpen && (
        <div
          className={resizeLevel >= 11 ? "tvp-resizeChatScreen" : "tvp-chatScreen"}
          onClick={handleMarkRead}
          data-testid="chat-screen"
        >
          <div
            className="tvp-chatHeader"
            aria-labelledby="chat-heading"
            role="dialog"
            aria-live="polite"
          >
            <h2 id="chat-heading">Talk to Proctor</h2>

            <div className="tvp-chatButtonWrapper">
              {" "}
              {configuration.audio_call && <MessageAudioBox />}
              <button onClick={Props.handleChatClose} className="tvp-closeChat">
                <img src={Closebtn} alt="close chat" />
              </button>
            </div>
          </div>

          {chatMessages ? (
            chatMessages?.length ? (
              <MessageList chatMessages={chatMessages} />
            ) : (
              <div className="tvp-flex tvp-flex-col tvp-flex-grow"></div>
            )
          ) : (
            <img className="tvp-miniLoader" src={Loader} alt="loader" />
          )}
          <MessageInput handleSendMessage={handleSendMessage} active={true} />
        </div>
      )}
    </>
  );
};

export default ChatView;

import React, { useEffect, useMemo, useState } from "react";
import Header from "components/Header";
import { FormattedMessage } from "react-intl";

import FooterLogo from "components/FooterLogo";
import Base from "components/layouts/Base";
import Rating from "components/Rating";
import SessionCompletedImg from "assets/img/session_completed.svg";
import Button from "components/Button";
import { FeedbackApiPayload, useSubmitFeedbackMutation } from "../services/feedback";
import { useAppSelector } from "../store/store";
import { SessionScope } from "../globals/enums";

const Feedback = () => {
  const [experienceRating, setExperienceRating] = useState(0);
  const [proctorExperience, setProctorExperience] = useState(0);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [submitFeedback, { isLoading, isSuccess, isError }] = useSubmitFeedbackMutation();
  const globalState = useAppSelector((state) => state.app);
  const [isSessionEnded, setIsSessionEnded] = useState(false);

  useEffect(() => {
    let timeout;
    if (isSessionEnded || isSuccess) {
      timeout = setTimeout(() => {
        const draggableComponent = document?.getElementById("tvp_recorder_container");
        if (draggableComponent) {
          while (draggableComponent.firstChild) {
            draggableComponent.removeChild(draggableComponent.firstChild);
          }
        }

        const element = document?.getElementById("proview_root");
        element && (element.style.display = "none");
        window.ProctorClient3.finalCallBack && window.ProctorClient3.finalCallBack();
      }, 2000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isSessionEnded, isSuccess]);

  const getPayload = (isLiveProctoring: boolean) => {
    if (isLiveProctoring) {
      const payload = {
        proctor_rating: proctorExperience,
        session_rating: experienceRating,
        text: additionalFeedback,
        session_uuid: globalState.session.old_session_uuid
      };
      if (!proctorExperience) delete payload.proctor_rating;
      if (!experienceRating) delete payload.session_rating;
      return payload;
    } else {
      return {
        session_rating: experienceRating,
        text: additionalFeedback,
        session_uuid: globalState.session.old_session_uuid
      };
    }
  };

  const updateFeedback = async () => {
    if (!disableButton && !isLoading) {
      const payload: FeedbackApiPayload = getPayload(
        globalState.session.session_type === SessionScope.LiveProctor
      );

      await submitFeedback(payload);
    }
  };

  const disableButton = useMemo(() => {
    if (globalState.session.session_type === SessionScope.LiveProctor)
      return !proctorExperience && !experienceRating;
    else return !experienceRating;
  }, [globalState.session.session_type, proctorExperience, experienceRating]);

  return (
    <Base>
      <Header title={""} titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-dgray tvp-ml-2.5"} />
      {isSuccess || isSessionEnded ? (
        <div className="tvp-flex tvp-flex-col tvp-items-center tvp-justify-center tvp-m-auto">
          <img className="tvp-w-[116px] tvp-h-[116px]" src={SessionCompletedImg} />
          <div className="tvp-text-center tvp-text-sm tvp-text-[#4a4a4a] tvp-w-[185px] tvp-mt-4">
            <FormattedMessage id="page.thankyouMessage" />
          </div>
        </div>
      ) : (
        <div className="tvp-mb-auto">
          <section
            className="tvp-flex tvp-flex-col tvp-items-center tvp-mb-10"
            data-testid="session_experience"
          >
            <p className="tvp-clr-dgray tvp-mb-3 tvp-text-sm tvp-mt-4">
              <FormattedMessage id="page.Feedback.experience" />
            </p>
            <Rating rating={experienceRating} updateRating={setExperienceRating} />
          </section>

          {globalState.session.session_type === SessionScope.LiveProctor && (
            <section
              className="tvp-flex tvp-flex-col tvp-items-center tvp-mb-10"
              data-testid="proctor_experience"
            >
              <p className="tvp-clr-dgray tvp-mb-3 tvp-text-sm tvp-mt-4">
                <FormattedMessage id="page.Feedback.proctorExperience" />
              </p>
              <Rating rating={proctorExperience} updateRating={setProctorExperience} />
            </section>
          )}
          <section className="tvp-flex tvp-flex-col tvp-items-center tvp-mb-4">
            <p className="tvp-clr-dgray tvp-mb-3 tvp-text-sm tvp-mt-4">
              <FormattedMessage id="page.Feedback.additionalFeedback" />
            </p>
            <textarea
              id="feedback_textarea"
              onClick={(e: any) => {
                // Hotfix!!! needs refactor since textarea is unresponsive for live sessions
                e.stopPropagation();
                e.target.focus();
              }}
              data-testid="feedback-textarea"
              className="tvp-w-[420px] tvp-border-slate tvp-p-3 tvp-rounded tvp-max-w-fill-available"
              rows={3}
              onChange={(e) => setAdditionalFeedback(e.target.value)}
            />
          </section>
          {isError && (
            <p className="tvp-clr-red tvp-mb-3 tvp-text-sm tvp-mt-4">Something went wrong.</p>
          )}
          <div className="tvp-flex tvp-gap-3 tvp-justify-center tvp-mt-auto">
            <Button
              label={<FormattedMessage id="button.Skip" />}
              className={
                "tvp-py-2 tvp-w-24 tvp-rounded tvp-border-solid tvp-border-1 tvp-cursor-pointer tvp-brand-color tvp-bdr-green"
              }
              onClick={() => setIsSessionEnded(true)}
            />
            <Button
              label={<FormattedMessage id="button.Submit" />}
              className={`tvp-py-2 tvp-w-24 tvp-rounded tvp-border-solid tvp-border-1 ${
                disableButton ? "tvp-cursor-not-allowed" : "tvp-cursor-pointer"
              }  ${
                disableButton
                  ? "tvp-disabled tvp-bg-lgray tvp-clr-dgray  tvp-bdr-lgray tvp-border-solid tvp-border-2 "
                  : "tvp-bg-green tvp-bdr-green tvp-clr-white"
              } `}
              onClick={updateFeedback}
              disabled={disableButton}
            />
          </div>
        </div>
      )}
      <FooterLogo />
    </Base>
  );
};

export default Feedback;

// CONFIGS
import { ProctoringRoute, getEndpoint } from "routes/route";

import Button from "components/Button";
import Camera from "assets/img/camera.png";
import CameraGrey from "assets/img/camera_grey.gif";
import CameraRed from "assets/img/camera_red.png";
import FooterLogo from "components/FooterLogo";
import { FormattedMessage } from "react-intl";
import HardwareTestIcon from "components/HardwareTestIcon";
// COMPONENTS
import Header from "components/Header";
import Notification from "assets/img/notification.png";
import NotificationGrey from "assets/img/notification_grey.gif";
import NotificationRed from "assets/img/notification_red.png";
import React, { useContext, useEffect } from "react";
// ASSETS
import cameraCropped from "assets/img/camera_cropped.gif";
// HOOKS
import useDetectMediaDevices from "hooks/useDetectMediaDevices";
import {
  setIsFaceDetectionEnabled,
  setUiErrorScreen,
  setVideoStreamingEnabled,
  setIsCameraGrabEnabled
} from "store/app.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ErrorTypes, StreamingProvider } from "globals/enums";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";

import { useNavigate } from "react-router-dom";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import { AlertLogConfig } from "services/session-token";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { MediaContext, MediaPermissionsErrorType } from "context/MediaContext";
import usePermissionChange from "hooks/usePermissionChange";

// eslint-disable-next-line
const HardwareTest: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const [videoInputDetected, audioInputDetected, mediaDeviceLoading] = useDetectMediaDevices();

  const { mediaStream, mediaStreamError, start } = useContext(MediaContext);

  useEffect(() => {
    const mediaConstraints: MediaStreamConstraints = {
      video: {
        width: 720,
        height: 540
      }
    };
    start(mediaConstraints);
  }, []);

  /**
   * If all the hardware check passes then move the user to the next page
   */
  useEffect(() => {
    (async () => {
      if (
        videoInputDetected &&
        audioInputDetected &&
        mediaStream &&
        mediaStream?.getAudioTracks()?.length &&
        mediaStream?.getVideoTracks()?.length
      ) {
        alertHelper(alertConfig).raiseAlert(AlertName.CameraTestPassed, dispatch);

        if (globalState?.configuration.streaming_provider !== StreamingProvider.PlatformStreaming) {
          dispatch(setIsFaceDetectionEnabled({ isFaceDetectionEnabled: true }));
          globalState?.configuration.video_recording &&
            dispatch(setVideoStreamingEnabled({ isVideoStreamingEnabled: true }));
          dispatch(setIsCameraGrabEnabled({ isCameraGrabEnabled: true }));
        }
        setTimeout(() => {
          const getNextScreen: string = nextScreen(
            ProctoringRoute.HardwareTest,
            globalState.configuration,
            globalState.session.session_type
          );
          navigate(getEndpoint(getNextScreen));
        }, 1000);
      }
    })();
  }, [
    videoInputDetected,
    audioInputDetected,
    mediaStream,
    globalState.configuration.video_recording,
    globalState.configuration.streaming_provider,
    globalState.session.session_type,
    mediaStream
  ]);

  useEffect(() => {
    (async () => {
      if (mediaStreamError) {
        alertHelper(alertConfig).raiseAlert(AlertName.CameraTestFailed, dispatch);
      }
    })();
  }, [mediaStreamError]);

  usePermissionChange();

  const handleTroubleShoot = () => {
    dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.CameraInaccessibleError as string }));
    navigate(getEndpoint(ProctoringRoute.Error));
  };

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.hardwareTest.title" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
      />
      <Content>
        <p className="tvp-mx-auto">
          <FormattedMessage id="page.hardwareTest.popupDescription" />
        </p>
        <img
          className="tvp-mx-auto tvp-my-4"
          src={cameraCropped}
          alt="browser permission pop up icon"
          width="200"
        />
        <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
          <HardwareTestIcon
            state={videoInputDetected && audioInputDetected}
            mediaError={mediaStreamError}
            loadingState={mediaDeviceLoading}
            label="page.hardwareTest.icon.cameraAndMic.label"
            errorAltText="Camera and mic permission fail icon"
            successAltText="Camera and Mic permission success icon"
            loadingIcon={CameraGrey}
            successIcon={Camera}
            failureIcon={CameraRed}
          />
          <HardwareTestIcon
            state={true}
            loadingState={false}
            label="page.hardwareTest.icon.notifications.label"
            errorAltText="Notifications permission fail icon"
            successAltText="Notifications permission success icon"
            loadingIcon={NotificationGrey}
            successIcon={Notification}
            failureIcon={NotificationRed}
          />
        </div>
        {mediaStreamError === MediaPermissionsErrorType.UserPermissionDenied && (
          <p className="tvp-mx-auto tvp-max-w-lg tvp-clr-red" aria-live="polite">
            <FormattedMessage id="page.hardwareTest.error.UserPermissionDenied.label" />
          </p>
        )}
        {(mediaStreamError === MediaPermissionsErrorType.SystemPermissionDenied ||
          mediaStreamError === MediaPermissionsErrorType.CouldNotStartVideoSource) && (
          <p className="tvp-mx-auto tvp-max-w-lg tvp-clr-red" aria-live="polite">
            <FormattedMessage id="page.hardwareTest.error.SystemPermissionDenied.label" />
          </p>
        )}
        {mediaStreamError === MediaPermissionsErrorType.DeviceNotFound && (
          <p className="tvp-mx-auto tvp-max-w-lg tvp-clr-red" aria-live="polite">
            <FormattedMessage id="page.hardwareTest.error.DeviceNotFound.label" />
          </p>
        )}
        {mediaStreamError && (
          <Button
            label={<FormattedMessage id="button.troubleshoot" />}
            className={
              "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150"
            }
            onClick={handleTroubleShoot}
          />
        )}
      </Content>
      <FooterLogo />
    </Base>
  );
};

export default HardwareTest;

/* eslint-disable */
import React, { useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { RootState } from "store/store";
import { canvasDimensions } from "config/constant";
import { MediaContext } from "context/MediaContext";
import { getCameraGrabBlobPayload } from "helpers/blob-payload";
import { enqueueItem } from "store/cameraGrabSlice";
import { setUiErrorScreen } from "store/app.slice";
import { ErrorTypes } from "globals/enums";
import { handleError } from "helpers/sentry";

const useCameraGrab = () => {
  const { videoStream, setMediaStreamError } = useContext(MediaContext);
  const storage = useSelector((state: RootState) => state.app.storage);
  const session = useSelector((state: RootState) => state.app.session);
  const isCameraGrabEnabled = useSelector((state: RootState) => state.app.isCameraGrabEnabled);
  const dispatch = useAppDispatch();
  const cameraGrabTimer = useRef<NodeJS.Timeout | null>(undefined);

  const grabCamera = async () => {
    const videoElement = document.getElementById("recorder_video_custom") as HTMLVideoElement;
    if (videoElement) {
      // created different canvas for camera grabs so that it does not conflict with face detection rectangle
      const canvas = document.createElement("canvas");
      const { canvasWidth, canvasHeight } = canvasDimensions;
      canvas.height = canvasHeight;
      canvas.width = canvasWidth;
      const context = canvas.getContext("2d");
      context?.drawImage(videoElement!, 0, 0, canvasWidth, canvasHeight);

      canvas.toBlob(async (blob) => {
        dispatch(enqueueItem(getCameraGrabBlobPayload(storage, session, blob)));
      }, "image/png");
    }
  };

  React.useEffect(() => {
    if (isCameraGrabEnabled && videoStream && videoStream.active) {
      cameraGrabTimer.current = setInterval(() => {
        grabCamera();
      }, 10000);
    } else if (isCameraGrabEnabled) {
      setMediaStreamError(new DOMException("Track ended", "NotAllowedError"));
      handleError("Track ended error in camera grab");
      dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.MediaStreamError }));
      clearInterval(cameraGrabTimer.current!);
    } else {
      clearInterval(cameraGrabTimer.current!);
    }
  }, [isCameraGrabEnabled, videoStream]);

  return [isCameraGrabEnabled];
};

export default useCameraGrab;

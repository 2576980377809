import SplashScreenImg from "assets/img/splash_screen.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getEndpoint, ProctoringRoute } from "routes/route";
import mixpanel from "config/mixpanel";
import { ErrorTypes, ErrorTypesMessages, SessionScope, SessionStatus } from "globals/enums";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import {
  AlertLogConfig,
  SessionTokenApiPayload,
  SessionTokenApiResponse,
  useGenerateSessionTokenQuery,
  useGenerateVerificationCodeQuery,
  VerificationTokenApiPayload
} from "services/session-token";
import {
  setChannelState,
  setSessionStatus,
  setClientConfiguration,
  setPreviewStyle,
  setFireBaseState,
  setSessionTokenApiDetails,
  setUiErrorScreen,
  setVerificationTokenApiDetails,
  setPrecheckStartTime
} from "store/app.slice";
import useRefreshSASToken from "hooks/useRefreshSASToken";
import { FirebaseInit } from "services/external/firebase";
import { useNavigate } from "react-router";
import { allowedStartBufferTime, mixpanelToken, sbLaunchUrl } from "config/constant";
import { getInitialData } from "helpers/common";
import { GlobalAppState } from "globals/interfaces";
import { channelApi } from "services/channel";
import * as Sentry from "@sentry/react";
import { isSupportedBrowser, isTsbBrowser, getUserBrowser } from "helpers/browser-helper";
import { compareTime } from "helpers/compare-time";
import { useCreatePingQuery } from "services/ping";

declare global {
  interface Window {
    ProctorClient3: any;
    tv: any;
    pingIntervalId: NodeJS.Timer;
  }
}

const Splash = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const envDetails = getInitialData(window?.tv?.q[0], globalState.initScriptConfiguration);
  const {
    enforceTSB,
    redirectURL,
    blacklistedSoftwaresMac,
    blacklistedSoftwaresWindows,
    whitelistedSoftwaresWindows,
    whitelistedSoftwaresMac,
    minimizeOption,
    isScreenProtectionEnabled
  } = envDetails.clientConfiguration;
  const sessionDetails = useAppSelector((state: any) => state.app.session);
  const firebaseDb = globalState?.configuration?.firebase_db;
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const dispatch = useAppDispatch();
  const [sessionTokenApiPayload, setSessionTokenApiPayload] = useState<SessionTokenApiPayload>();

  const { data, error, refetch }: any = useCreatePingQuery(
    { session_token: sessionDetails?.session_token },
    {
      skip: !sessionDetails?.session_token || globalState.sessionStatus === "completed"
    }
  );

  useEffect(() => {
    dispatch(
      setClientConfiguration({
        additionalInstruction: envDetails?.clientConfiguration.additionalInstruction as string,
        referenceLinks: envDetails?.clientConfiguration.referenceLinks
      })
    );
    dispatch(
      setPreviewStyle({
        previewStyle: envDetails?.previewStyle
      })
    );
  }, []);

  const isProviewLoadedInTSB = isTsbBrowser();

  useEffect(() => {
    if (isProviewLoadedInTSB && enforceTSB) {
      const formData = {
        minimize_option: minimizeOption,
        BlacklistedSoftwaresWindows: blacklistedSoftwaresWindows,
        BlacklistedSoftwaresMac: blacklistedSoftwaresMac,
        WhitelistedSoftwaresWindows: whitelistedSoftwaresWindows,
        WhitelistedSoftwaresMac: whitelistedSoftwaresMac,
        IsScreenProtectionEnabled: isScreenProtectionEnabled
      };
      window.parent.postMessage(
        {
          msg: "definerestrictions",
          ...formData
        },
        "*"
      );

      window.addEventListener(
        "message",
        function (event) {
          if (event.data.msg == "definerestrictionscompleted") {
            console.log("Successfully applied the settings via script");
          }
        },
        false
      );
    }
  }, [enforceTSB, isProviewLoadedInTSB]);

  useEffect(() => {
    if (enforceTSB && redirectURL && !isProviewLoadedInTSB) {
      const encodedURl = encodeURIComponent(redirectURL);
      const addressUrl = `${sbLaunchUrl}?redirect_url=${decodeURIComponent(encodedURl)}`;
      window.location.replace(addressUrl);
    }
  }, [enforceTSB, redirectURL, isProviewLoadedInTSB]);

  const tokenPayload: VerificationTokenApiPayload = {
    proctor_token: envDetails.proctor_token,
    attendee_external_id: envDetails.attendee_external_id,
    session: {
      external_id: envDetails.session.external_id,
      scope: envDetails.session.scope,
      title: envDetails.session.title
    },
    configuration: {}
  };
  if (enforceTSB) {
    const enforce_tsb = {
      current_browser: isTsbBrowser() ? "TSB" : getUserBrowser(),
      redirect_url: redirectURL,
      minimize_option: minimizeOption,
      blacklisted_softwares_windows: blacklistedSoftwaresWindows,
      blacklisted_softwares_mac: blacklistedSoftwaresMac,
      whitelisted_softwares_windows: whitelistedSoftwaresWindows,
      whitelisted_softwares_mac: whitelistedSoftwaresMac,
      isScreenProtectionEnabled: isScreenProtectionEnabled
    };
    tokenPayload.configuration = { ...tokenPayload.configuration, enforce_tsb };
  }

  const {
    data: verificationCodeResponse,
    isSuccess: isVerificationCodeSuccess,
    isError: isVerificationCodeError,
    error: verificationCodeError
  } = useGenerateVerificationCodeQuery(tokenPayload);

  const {
    data: sessionTokenApiResult,
    isLoading: isSessionTokenFetching,
    isSuccess: isSessioTokenSuccess,
    isError: isSessionTokenError,
    error: sessionTokenError
  } = useGenerateSessionTokenQuery(sessionTokenApiPayload, {
    skip: !sessionTokenApiPayload
  });

  useRefreshSASToken();
  const [getChannel] = channelApi.endpoints.getChannel.useLazyQuery();

  const initializeFeatures = (sessionTokenResponse: SessionTokenApiResponse) => {
    const sessionId = sessionTokenResponse?.session_id;
    if (sessionId) {
      Sentry.setTag("session_id", sessionId);
      const isMixpanel_analytics = sessionTokenResponse?.configuration?.mixpanel_analytics;
      if (mixpanelToken && isMixpanel_analytics) {
        const profileId = globalState?.initScriptConfiguration?.profile_id as string;
        mixpanel.init(sessionTokenResponse, profileId);
        mixpanel.identify(profileId);
        mixpanel.setProfile();
        mixpanel.track("proctoring client initiated");
        mixpanel.track(ProctoringRoute.TermsOfUse);
      }
    }
  };

  useEffect(() => {
    if (!isSupportedBrowser()) {
      dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.BrowserNotSupported }));
      navigate(getEndpoint(ProctoringRoute.Error));
    }
  }, []);

  useEffect(() => {
    if (isVerificationCodeSuccess) {
      const verificationCode = verificationCodeResponse.verification_code;
      setSessionTokenApiPayload({
        verification_token: verificationCode
      });
      dispatch(
        setVerificationTokenApiDetails({ verificationTokenApiResponse: verificationCodeResponse })
      );
    }
  }, [isVerificationCodeSuccess, verificationCodeResponse, isVerificationCodeError]);

  useEffect(() => {
    if (isSessioTokenSuccess) {
      const sessionTokenResult = sessionTokenApiResult as unknown as SessionTokenApiResponse;
      initializeFeatures(sessionTokenResult);
      dispatch(setSessionTokenApiDetails({ sessionTokenApiResponse: sessionTokenResult }));
      alertHelper(alertConfig).raiseAlert(AlertName.SessionJoined, dispatch);
      if (sessionTokenResult.session_type === SessionScope.LiveProctor) {
        const fetchChannelData = async () => {
          try {
            const channel = await getChannel().unwrap();
            dispatch(setChannelState(channel));
          } catch (error) {
            console.error("Error occurred while channel:", error);
          }
        };
        fetchChannelData();
      }
    }
  }, [isSessioTokenSuccess, sessionTokenApiResult]);

  const calculateTimeLeft = async (appDetails) => {
    const pingApiResponse = await refetch();
    const currentTime = new Date(`${pingApiResponse.data}`);
    const examStartTime = new Date(appDetails?.configuration?.valid_from);
    examStartTime.setMinutes(
      examStartTime.getMinutes() -
        allowedStartBufferTime -
        appDetails?.configuration?.start_buffer_time
    );
    const timeLeft = compareTime(currentTime, examStartTime);
    if (timeLeft > 0) {
      dispatch(
        setPrecheckStartTime({
          precheckStartTime: allowedStartBufferTime + appDetails?.configuration?.start_buffer_time
        })
      );
      dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.TestNotStarted }));
      navigate(getEndpoint(ProctoringRoute.Error));
      return;
    }
  };

  useEffect(() => {
    if (isSessioTokenSuccess && sessionDetails?.session_token) {
      const sessionTokenResult = sessionTokenApiResult as SessionTokenApiResponse;
      if (sessionTokenResult?.session_type === SessionScope.LiveProctor) {
        calculateTimeLeft(globalState);
      }
      firebaseDb && FirebaseInit(sessionTokenResult?.session_id as number, dispatch, { navigate });
      navigate(getEndpoint(ProctoringRoute.TermsOfUse));
      return;
    } else if (isSessionTokenError || isVerificationCodeError) {
      const errorObj: any = sessionTokenError || verificationCodeError;
      let errorType, fireBaseSessionStatus;
      if (errorObj?.status === 403) {
        switch (errorObj?.message) {
          case ErrorTypesMessages.SessionTerminationError:
            errorType = ErrorTypes.TerminatedError;
            dispatch(setSessionStatus({ status: SessionStatus.Terminated }));
            fireBaseSessionStatus = SessionStatus.Terminated;
            break;
          case ErrorTypesMessages.NoShowError:
            errorType = ErrorTypes.NoShowError;
            fireBaseSessionStatus = SessionStatus.NoShow;
            break;
          case ErrorTypesMessages.SessionCompletedError:
            errorType = ErrorTypes.SessionCompleted;
            dispatch(setSessionStatus({ status: SessionStatus.Completed }));
            fireBaseSessionStatus = SessionStatus.Completed;
            break;
          case ErrorTypesMessages.SlotTimeAlreadyPassed:
            errorType = ErrorTypes.SlotTimeAlreadyPassed;
            break;
          default:
            break;
        }
      } else if (errorObj?.status === 404) {
        errorType = ErrorTypes.SessionNotScheduled;
      } else {
        errorType = ErrorTypes.ProviewLoadingError;
      }
      fireBaseSessionStatus &&
        dispatch(setFireBaseState({ status: fireBaseSessionStatus as string }));
      dispatch(setUiErrorScreen({ uiErrorScreen: errorType as string }));
      navigate(getEndpoint(ProctoringRoute.Error));
    }
  }, [
    sessionTokenApiResult,
    isSessionTokenFetching,
    isSessioTokenSuccess,
    isSessionTokenError,
    firebaseDb,
    isVerificationCodeError,
    sessionDetails
  ]);

  useEffect(() => {
    if (
      error?.data?.status === 403 &&
      error?.data?.message === "Session Token is not active or is deleted" &&
      globalState.sessionStatus !== "completed"
    ) {
      dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.SessionExpired as string }));
      navigate(getEndpoint(ProctoringRoute.Error));
    }
  }, [sessionDetails, data, error]);

  return (
    <>
      <div className="tvp-mx-auto" id="basediv">
        {SplashScreenImg && (
          <div className="tvp-rounded-lg tvp-py-28 tvp-px-32 tvp-bg-white">
            <img src={SplashScreenImg} alt="Proview screen" />
          </div>
        )}
      </div>
    </>
  );
};

export default Splash;

import React, { useContext, useState, useRef } from "react";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import IdCard from "assets/img/id_card.png";
import Loader from "assets/img/loader_green.gif";
import VideoPlayer from "components/VideoPlayer";
import { FormattedMessage } from "react-intl";
import { useAppSelector, useAppDispatch } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import { ErrorMessage, PhotoIdFileSource } from "globals/enums";

// Components
import Canvas from "components/Canvas";
// Helpers
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { getBase64 } from "helpers/file-helper";
import { AlertLogConfig } from "services/session-token";
import { photoIdMaxFileSize } from "config/constant";
import { handleError } from "helpers/sentry";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { MediaContext } from "context/MediaContext";
import { usePhotoIdCapture, photoIdCaptureUploadApi } from "services/photoIdCaptureUpload";

const PhotoIdVerification = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const [{ fileSource, file, validationError }, setPhotoVerification] = useState({
    fileSource: "",
    file: "",
    validationError: false
  });
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { mediaStream } = useContext(MediaContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [
    photoIdCapture,
    { isUninitialized: photoIdUninitialized, isLoading: photoIdLoading, isError: photoIdError }
  ] = usePhotoIdCapture();

  const handleResetApiState = () => {
    if (!photoIdUninitialized) {
      dispatch(photoIdCaptureUploadApi.util.resetApiState());
    }
  };

  const handleSelectFile = () => {
    fileInputRef.current?.click();
  };

  const uploadClick = () => {
    uploadPhotoId();
  };

  const handleOnCapture = () => {
    setPhotoVerification((prevState) => ({
      ...prevState,
      fileSource: PhotoIdFileSource.ImageCaptured,
      file: "",
      validationError: false
    }));
    handleResetApiState();
  };

  const handleOnRecapture = () => {
    setPhotoVerification((prevState) => ({ ...prevState, fileSource: "" }));
    handleResetApiState();
  };

  const uploadPhotoId = async () => {
    let payload = canvasRef.current?.toDataURL("image/png");
    if (file) {
      payload = file;
    }
    try {
      const alertData = alertHelper(alertConfig).getAlert(AlertName.PhotoIdCapture, payload, null);
      await photoIdCapture(alertData).unwrap();
      await alertHelper(alertConfig).raiseAlert(AlertName.IdCardCaptureSuccess, dispatch);
      const getNextScreen = nextScreen(
        ProctoringRoute.PhotoIDVerification,
        globalState.configuration,
        globalState.session.session_type
      );
      navigate(getEndpoint(getNextScreen));
    } catch (e: any) {
      if (e === ErrorMessage.NoFaceDetected || e?.message === ErrorMessage.NoFaceDetected) {
        console.log(e);
      } else {
        handleError(e);
      }
      alertHelper(alertConfig).raiseAlert(AlertName.IdDCardCaptureFailure, dispatch);
      setPhotoVerification((prevState) => ({ ...prevState, fileSource: "", file: "" }));
    }
  };

  const renderError = () => {
    if (validationError) {
      return (
        <div className="tvp-clr-red tvp-buttonPart" aria-live="polite">
          <FormattedMessage id="page.photoIdCapture.file.upload.error" />
        </div>
      );
    }
    return (
      <div className="tvp-clr-red tvp-buttonPart" aria-live="polite">
        <FormattedMessage id="error.nofaceDetected" />
      </div>
    );
  };

  const onFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileSelected = event?.target?.files![0];
    if (fileSelected) {
      handleResetApiState();
      setPhotoVerification((prevState) => ({
        ...prevState,
        fileSource: PhotoIdFileSource.IdCardUpload,
        validationError: false
      }));
      const fileSizeInKB = fileSelected.size / photoIdMaxFileSize;
      if (fileSizeInKB > photoIdMaxFileSize) {
        setPhotoVerification((prevState) => ({ ...prevState, validationError: true }));
        return;
      }
      const fileBase64: any = await getBase64(fileSelected);
      setPhotoVerification((prevState) => ({ ...prevState, file: fileBase64 }));
    }
  };

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.photoIdCapture.title" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <div className="tvp-justify-between tvp-px-2 tvp-flex  tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-col-row ">
          <div className="tvp-flex tvp-flex-col tvp-mt-3 tvp-mb-3 tvp-photo-verification-page tvp-justify-center  ">
            {!mediaStream && <img className="tvp-miniLoader" src={Loader} alt="loader" />}
            {mediaStream && (
              <>
                <div className="tvp-mb-4 tvp-text-xs tvp-phto-text-folder">
                  <FormattedMessage
                    id="page.photoIdCapture.instruction"
                    values={{ strong: (chunks) => <strong>{chunks}</strong> }}
                  />
                </div>
                <div className="tvp-mb-4 tvp-photIdVideoPlayerWrapper">
                  {mediaStream && (
                    <VideoPlayer
                      videoRef={videoRef}
                      mediaStream={mediaStream}
                      playerWidth={"200px"}
                    />
                  )}
                  {fileSource === PhotoIdFileSource.ImageCaptured && (
                    <Canvas videoRef={videoRef} canvasRef={canvasRef} height={150} width={200} />
                  )}
                </div>
                <div className="tvp-buttonPart">
                  <span className="tvp-flex tvp-flex-col tvp-items-center">
                    {(!fileSource || fileSource === PhotoIdFileSource.IdCardUpload) && (
                      <Button
                        label={<FormattedMessage id="button.capture" />}
                        tabIndex={2}
                        className={
                          photoIdLoading
                            ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray"
                            : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green"
                        }
                        onClick={handleOnCapture}
                      />
                    )}
                    {fileSource === PhotoIdFileSource.ImageCaptured && (
                      <Button
                        label={<FormattedMessage id="button.reCapture" />}
                        tabIndex={2}
                        className={
                          photoIdLoading
                            ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray"
                            : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-red tvp-bdr-red tvp-w-150 tvp-m-auto"
                        }
                        onClick={handleOnRecapture}
                        disabled={photoIdLoading}
                      />
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="tvp-mt-3 tvp-divider-identity">
            <span className="tvp-dividerText-identity">
              <FormattedMessage id="page.photoid.divider" />
            </span>
          </div>

          <div className="tvp-flex tvp-flex-col tvp-mt-3 tvp-photo-verification-page">
            <div className="tvp-mb-4 tvp-text-xs tvp-phto-text-folder">
              {
                <FormattedMessage
                  id="page.photoIdCapture.upload.instruction"
                  values={{ strong: (chunks) => <strong>{chunks}</strong> }}
                />
              }
              {
                <p>
                  <FormattedMessage
                    id="page.photoIdCapture.filesize.instruction"
                    values={{ strong: (chunks) => <strong>{chunks}</strong> }}
                  />
                </p>
              }
            </div>
            <div className="tvp-mb-4 tvp-photIdVideoPlayerWrapper">
              {file && <img className="tvp-IdPreview" src={file} alt="IdCard" />}
              {!file && <img className="tvp-mt-4 IdCard" src={IdCard} alt="Photo ID icon" />}
            </div>
            <label className="tvp-cursor-pointer" htmlFor="fileUpload">
              <input
                className="tvp-custom-file-input"
                id="fileUpload"
                type="file"
                onChange={onFileSelected}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
                placeholder="Select photo id file"
                ref={fileInputRef}
                accept=".png,.jpg,.jpeg"
                style={{ display: "none" }}
              />
              <Button
                label={<FormattedMessage id="button.selectFile" />}
                tabIndex={3}
                className={
                  photoIdLoading
                    ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray"
                    : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green"
                }
                onClick={handleSelectFile}
                disabled={fileSource ? photoIdLoading : false}
              />
            </label>
          </div>
        </div>
        {(photoIdError || validationError) && renderError()}
        <div className="tvp-buttonPart">
          <Button
            tabIndex={4}
            label={<FormattedMessage id="page.photoIdCapture.upload" />}
            className={
              !fileSource || photoIdLoading || photoIdError || validationError
                ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray"
                : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green"
            }
            onClick={fileSource ? uploadClick : () => {}}
            disabled={!fileSource || photoIdLoading || photoIdError || validationError}
          />
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
};
export default PhotoIdVerification;

import { useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { store, useAppDispatch, useAppSelector } from "store/store";
import { createRoot } from "react-dom/client";
import ChatWrapper from "components/Chat/ChatWrapper";
import { GlobalAppState } from "globals/interfaces";
import TranslationProvider from "providers/translation-provider";
import Toastcontainer from "components/ToastContainer";
import useRegister from "hooks/useRegister";
import DraggedDiv from "components/layouts/Dragable";
import { MediaContext } from "context/MediaContext";
import { useCreatePingQuery } from "services/ping";
import { pingInterval } from "config/constant";
import { MediaStreamErrorNames } from "globals/enums";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { showProview } from "helpers/showProview";
import { pauseProctor } from "helpers/pauseProctor";
import { useNavigate } from "react-router-dom";

const Shell = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const sessionDetails = useAppSelector((state: any) => state.app.session);
  const { mediaStreamError } = useContext(MediaContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useRegister();

  useCreatePingQuery(
    { session_token: sessionDetails?.session_token },
    {
      skip: !sessionDetails?.session_token || globalState.sessionStatus === "completed",
      pollingInterval: pingInterval
    }
  );

  useEffect(() => {
    if (mediaStreamError) {
      if (mediaStreamError?.name === MediaStreamErrorNames.TrackFailedError) {
        navigate(getEndpoint(ProctoringRoute.Error));
      } else if (mediaStreamError.name === MediaStreamErrorNames.AudioHardwareFailedError) {
        showProview();
        navigate(getEndpoint(ProctoringRoute.HardwareTestInstructions));
      } else if (mediaStreamError.name === MediaStreamErrorNames.VideoHardwareFailedError) {
        showProview();
        navigate(getEndpoint(ProctoringRoute.FaceCaptureInstructions));
      }
    }
  }, [mediaStreamError]);

  useEffect(() => {
    document.addEventListener("Terminated", async () => {
      clearInterval(window.pingIntervalId as any);
      const draggableComponent = document.getElementById("tvp_recorder_holder");
      draggableComponent.style.display = "none";
    });

    window.ProctorClient3 = {
      eventHandlers: {},
      stop: (finalCallBack: any) => {
        window.ProctorClient3.finalCallBack = finalCallBack;
        const event = new CustomEvent("StopProctorClient");
        document.dispatchEvent(event);
        const videoTracks = new CustomEvent("StopVideoTracks");
        document.dispatchEvent(videoTracks);
        clearInterval(window.pingIntervalId as any);
      },
      pause: (code: string, reason?: string, note?: string) => {
        pauseProctor(dispatch, navigate, code, reason, note);
      },
      giveControl: () => {
        if (window?.tv) {
          return window?.tv.q[0][2];
        }
        return null;
      },
      networkDisconnectionCallback: () => {
        if (window?.tv && typeof window.tv.q[0][2]?.networkDisconnectionCallback === "function") {
          return window?.tv.q[0][2]?.networkDisconnectionCallback();
        }
      },
      trigger(eventNames: string, data: any) {
        const eventNameList = eventNames.split(" ");
        if (eventNameList && eventNameList.length > 0) {
          eventNameList.forEach((eventName) => {
            if (this.eventHandlers[eventName]) {
              this.eventHandlers[eventName].forEach((handler) => {
                handler(data);
              });
            }
          });
        }
      },
      on(eventName: string, callback) {
        if (!this.eventHandlers[eventName]) {
          this.eventHandlers[eventName] = [];
        }
        this.eventHandlers[eventName].push(callback);
      },
      isPhotoIdFailed: false,
      isTextEnvFailed: false
    };
    return () => clearInterval(window.pingIntervalId as any);
  }, []);

  const createVideoHolderRoot = () => {
    const container = document.createElement("div");
    container.className = "tvp-proview_root";
    const root = createRoot(container);
    root.render(
      <Provider store={store}>
        <DraggedDiv />
      </Provider>
    );
    document.body.appendChild(container);
  };

  const createToastContainer = () => {
    const toastContainer = document.createElement("div") as HTMLElement;
    toastContainer.id = "tvp-toast-container";
    toastContainer.style.zIndex = "3000";
    toastContainer.className = "tvp-proview_root";
    document.body.appendChild(toastContainer);
    return toastContainer;
  };

  useEffect(() => {
    const toastContainer = document.getElementById("tvp-toast-container") as HTMLElement;
    if (!toastContainer) {
      const container = createToastContainer();
      container &&
        createRoot(container).render(
          <Provider store={store}>
            <TranslationProvider>
              <Toastcontainer />
            </TranslationProvider>
          </Provider>
        );
    }

    createVideoHolderRoot();
  }, []);

  const handleAppendChat = () => {
    const chatContainer = document.getElementById("tvp-chat-container");
    if (chatContainer) {
      const commentRoot = createRoot(chatContainer);
      commentRoot.render(
        <Provider store={store}>
          <ChatWrapper />
        </Provider>
      );
    }
  };

  useEffect(() => {
    globalState.chatService && handleAppendChat();
  }, [globalState.chatService]);

  return <></>;
};

export default Shell;

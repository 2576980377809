import { livekitWSUrl, maxConnectionRetries, retryTimeoutInMS } from "config/constant";
import { StreamingProvider } from "globals/enums";
import { RecordingService, RecordingServiceOptions } from "globals/interfaces";
import { handleError } from "helpers/sentry";
import { store } from "store/store";
import { streamApi } from "./stream";
import {
  AudioPresets,
  ConnectionQuality,
  DisconnectReason,
  LocalAudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Room,
  RoomEvent,
  Track,
  VideoPresets
} from "livekit-client";

export class LivekitService implements RecordingService {
  public livekitRoom;
  private options: RecordingServiceOptions = {};
  private retryCount = 0;

  async connect(options: RecordingServiceOptions) {
    this.options = options;
    try {
      const room = new Room({
        disconnectOnPageLeave: false,
        publishDefaults: {
          videoCodec: "vp9",
          backupCodec: {
            codec: "vp8"
          },
          videoSimulcastLayers: [VideoPresets.h360, VideoPresets.h540]
        },
        videoCaptureDefaults: {
          resolution: VideoPresets.h540.resolution
        }
      });
      await room.connect(livekitWSUrl, options.streamingToken, {
        autoSubscribe: false
      });

      room.on(RoomEvent.Disconnected, (reason) => {
        if (reason === DisconnectReason.DUPLICATE_IDENTITY) return;

        if (this.retryCount < maxConnectionRetries) {
          return this.reconnect();
        }
        handleError("Livekit disconnected");
        this.options.onDisconnect();
      });

      room.on(RoomEvent.Reconnecting, () => {
        this.options.onReconnecting();
      });

      room.on(RoomEvent.Reconnected, () => {
        this.options.onReconnected();
      });

      room.on(RoomEvent.LocalTrackPublished, (publish) => {
        if (
          room.localParticipant?.tracks.size >= 1 &&
          publish.track.source != Track.Source.ScreenShare
        ) {
          const audioTrackSid =
            room.localParticipant?.audioTracks.entries().next().value?.[0] ?? null;
          const videoTrackSid =
            room.localParticipant?.videoTracks.entries().next().value?.[0] ?? null;

          if (audioTrackSid || videoTrackSid) {
            const tracks: { videoTrackSid?: string; audioTrackSid?: string } = {
              ...(videoTrackSid && { videoTrackSid }),
              ...(audioTrackSid && { audioTrackSid })
            };
            this.options.onAudioVideoTrackPublished(
              {
                participantSid: room.localParticipant?.sid,
                tracks
              },
              StreamingProvider.Livekit
            );
          }
        }
      });

      room.on(RoomEvent.ConnectionQualityChanged, () => {
        const isGoodQuality = this.handleNetworkQualityChange(room);
        options.onNetworkQualityChange(isGoodQuality);
      });

      options.trackPublished(room);
      this.livekitRoom = room;
      this.retryCount = 0;
      console.log("connected", room.localParticipant);

      if (!options.tracks) {
        return;
      }
      const newVideoTrack = options.tracks.find((track) => track.kind === "video");
      const newAudioTrack = options.tracks.find((track) => track.kind === "audio");

      if (newVideoTrack) {
        await room.localParticipant.publishTrack(newVideoTrack.clone(), {
          name: "camera",
          source: Track.Source.Camera
        });
      }

      if (newAudioTrack) {
        await room.localParticipant.publishTrack(newAudioTrack.clone(), {
          name: "audio",
          source: Track.Source.Microphone,
          audioPreset: AudioPresets.speech
        });
      }
    } catch (e: any) {
      if (this.retryCount < maxConnectionRetries) {
        return this.reconnect();
      }
      handleError("Livekit service connection failed");
      handleError(e);
      options.onConnectionFailure(e);
    }
  }

  handleNetworkQualityChange = (room) => {
    return (
      room.localParticipant.connectionQuality === ConnectionQuality.Good ||
      room.localParticipant.connectionQuality === ConnectionQuality.Excellent
    );
  };

  createLocalVideoTrack(track: MediaStreamTrack) {
    if (track) {
      const trackClone = track.clone();
      const localTrack = new LocalVideoTrack(trackClone);
      return localTrack;
    }
  }

  async unpublishTrack(publishedTrack: LocalTrackPublication) {
    if (publishedTrack) {
      await this.livekitRoom.localParticipant.unpublishTrack(publishedTrack);
    }
  }

  async flipVideo(tracks: MediaStreamTrack[]) {
    if (!tracks?.length) {
      return;
    }

    try {
      if (this.options?.tracks?.length >= 2) {
        await this.livekitRoom.localParticipant.unpublishTrack(this.options.tracks[0]);
        await this.livekitRoom.localParticipant.unpublishTrack(this.options.tracks[1]);
      }
      const newVideoTrack = tracks.find((track) => track.kind === "video");
      const newAudioTrack = tracks.find((track) => track.kind === "audio");
      if (!newVideoTrack) {
        throw new Error("No video track found");
      }
      if (!newAudioTrack) {
        throw new Error("No audio track found");
      }
      await this.livekitRoom.localParticipant.publishTrack(
        this.createLocalVideoTrack(newVideoTrack),
        {
          name: "camera",
          source: Track.Source.Camera
        }
      );
      await this.livekitRoom.localParticipant.publishTrack(new LocalAudioTrack(newAudioTrack), {
        name: "audio",
        source: Track.Source.Microphone,
        audioPreset: AudioPresets.speech
      });
      this.options.tracks = [newVideoTrack, newAudioTrack];
    } catch (e) {
      this.options.onConnectionFailure(e);
    }
  }

  async reconnect(): Promise<void> {
    console.log(`Livekit reconnect attempt: ${this.retryCount}`);
    try {
      const response = await store.dispatch(
        streamApi.endpoints.streamParticipant.initiate({
          participant_group_id: this.options.participantGroupId
        })
      );
      setTimeout(() => {
        const options = {
          ...this.options,
          streamingToken: "data" in response ? response.data.token : this.options.streamingToken
        };
        this.connect(options);
      }, retryTimeoutInMS);
      this.retryCount++;
    } catch (e) {
      handleError("Livekit Reconnection Failed");
    }
  }

  disconnect(): void {
    console.log("Livekit Disconnected");
    if (this.livekitRoom) {
      this.livekitRoom.disconnect();
    }
  }

  async publishTrack(track: LocalVideoTrack, trackName: string) {
    if (track) {
      const toPublishTrack = track;
      toPublishTrack.source = Track.Source.ScreenShare;

      try {
        const publishedTrack = await this.livekitRoom.localParticipant.publishTrack(
          toPublishTrack,
          { name: trackName }
        );
        return publishedTrack.track;
      } catch (e) {
        this.options.onConnectionFailure(e);
        return null;
      }
    }
  }
}

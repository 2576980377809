import * as Sentry from "@sentry/react";
import { ClientErrorTypes } from "globals/enums";

export async function handleError(e: any, source?: ClientErrorTypes) {
  if (source && window.ProctorClient3.giveControl()) {
    window.ProctorClient3.giveControl().errorCallback(e, source);
  }
  console.error(e);
  Sentry.captureException(e);
}

import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppSelector, useAppDispatch } from "store/store";
import { testEnvMaxDuration } from "config/constant";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { AlertLogConfig, SessionStorage } from "services/session-token";
import { MediaRecorderState } from "globals/enums";

// Assets & Components
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ViewDemo from "components/ViewDemo";
import Countdown from "components/Countdown";
import VideoPlayer from "components/VideoPlayer";

import Loader from "assets/img/loader_green.gif";

// Hooks
import useMediaRecorder from "hooks/useMediaRecorder";

// Services
import { useCreateBlobMutation } from "services/blobStorage";

import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import { alertHelper } from "../helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { MediaContext } from "context/MediaContext";
import { getSystemTime } from "helpers/common";

export type RecorderState = RecordingState | MediaRecorderState.Complete | undefined;

const EnvTestCapture = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [
    createBlob,
    {
      isLoading: blobCreationInProgress,
      isSuccess: blobCreationSuccess,
      isError: blobCreationError
    }
  ] = useCreateBlobMutation();
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mediaStream } = useContext(MediaContext);
  const [isDemoOpened, SetIsDemoOpened] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const storage = useSelector((state: RootState) => state.app.storage);
  const isSubmitDisabled = isSubmitted || blobCreationInProgress;

  const handleStopRecording = () => {
    console.log("Recorder state", recorderState);
    if (recorderState === MediaRecorderState.Recording) {
      recorder?.stop();
    }
    SetRecorderState(MediaRecorderState.Complete);
  };

  const [recorder, data] = useMediaRecorder(mediaStream, handleStopRecording, testEnvMaxDuration);
  const [recorderState, SetRecorderState] = React.useState<RecorderState>(
    recorder?.state || "inactive"
  );
  
  const generateFileName = (storage: SessionStorage) => {
    const randomNum = Math.floor(Math.random() * 90000) + 10000;
    const timestamp = getSystemTime().getTime();
    const testEnv = storage?.file_destination?.test_env;
    return `${testEnv}/${randomNum}_${timestamp}.webm`;
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (data) {
      const fileName = generateFileName(storage);
      setFileName(fileName);
      createBlob({
        url: `/${fileName}?${storage?.sas_token}`,
        data,
        // eslint-disable-next-line
        alert_type_id: +storage?.file_destination.test_env!
      }).unwrap();
    }
  };

  const handleStartRecording = () => {
    if(recorderState !== MediaRecorderState.Recording) {
      recorder?.start();
    }
    SetRecorderState(recorder?.state);
  };

  const handleRetake = () => {
    SetRecorderState("inactive");
    setIsSubmitted(false);
  }

  useEffect(() => {
    (async () => {
      if (blobCreationSuccess) {
        alertHelper(alertConfig).raiseAlert(
          AlertName.EnvironmentTestCaptureSuccess,
          dispatch,
          undefined,
          fileName
        );

        const getNextScreen = nextScreen(
          ProctoringRoute.TestEnvCapture,
          globalState.configuration,
          globalState.session.session_type
        );
        navigate(getEndpoint(getNextScreen));
      }
    })();
  }, [blobCreationSuccess]);

  useEffect(() => {
    (async () => {
      if (blobCreationError) {
        alertHelper(alertConfig).raiseAlert(AlertName.EnvironmentTestCaptureFailure, dispatch);
      }
    })();
  }, [blobCreationError]);

  return (
    <Base>
      {isDemoOpened && <ViewDemo onClick={() => SetIsDemoOpened(false)} />}
      {!isDemoOpened && (
        <>
          <Header
            title={<FormattedMessage id="page.EnvTestInstructions.header" />}
            titleClass="tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"
            tabIndex={1}
          />
          <Content>
            {!mediaStream && <img src={Loader} alt="loader" />}
            {mediaStream && (
              <>
                <div className="tvp-flex tvp-mb-3 tvp-videoText">
                  {recorderState === MediaRecorderState.InActive && (
                    <p className="tvp-mr-2">
                      <FormattedMessage id="page.EnvTestCapture.videoInstuction.text" />
                    </p>
                  )}
                  {recorderState === MediaRecorderState.Recording && (
                    <p className="tvp-mr-2">
                      <Countdown duration={testEnvMaxDuration} />
                    </p>
                  )}
                  {recorderState === MediaRecorderState.Complete && (
                    <p className="tvp-mr-2">
                      <FormattedMessage id="page.EnvTestCapture.recordingComplete.text" />
                    </p>
                  )}
                  {recorderState === MediaRecorderState.InActive && (
                    <Button
                      label={<FormattedMessage id="button.viewDemo" />}
                      tabIndex={2}
                      className={
                        "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-white tvp-clr-neutral tvp-bdr-green tvp-min-w-unset"
                      }
                      onClick={() => SetIsDemoOpened(true)}
                    />
                  )}
                </div>
                {recorderState !== MediaRecorderState.Complete ? (
                  !mediaStream ? (
                    <img src={Loader} alt="loader" />
                  ) : (
                    <div style={{ margin: "10px" }}>
                      <VideoPlayer
                        videoRef={videoRef}
                        mediaStream={mediaStream}
                        playerWidth={"200px"}
                      />
                    </div>
                  )
                ) : (
                  <>
                    <div className="tvp-envCheckRecordingComplete tvp-flex tvp-justify-center">
                      {data && (
                        <video
                          controls
                          controlsList="play timeline volume nofullscreen"
                          src={URL.createObjectURL(data)}
                        />
                      )}
                    </div>
                    {blobCreationError && isSubmitted ? (
                      <div className="tvp-w-full tvp-flex tvp-justify-center tvp-items-center tvp-p-1 tvp-clr-red">
                        Error uploading file
                      </div>
                    ) : null}
                  </>
                )}
                {recorderState === MediaRecorderState.InActive && (
                  <p className="tvp-mt-3">
                    <FormattedMessage id="page.EnvTestCapture.instruction.text" />
                  </p>
                )}
                <div className="tvp-buttonPart">
                  {recorderState === MediaRecorderState.InActive && (
                    <Button
                      label={<FormattedMessage id="button.startRecording" />}
                      className={
                        "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-green tvp-bdr-green"
                      }
                      onClick={handleStartRecording}
                    />
                  )}
                  {recorderState === MediaRecorderState.Recording && (
                    <Button
                      label={<FormattedMessage id="button.stopRecording" />}
                      className={
                        "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-red tvp-bdr-red tvp-mt-9"
                      }
                      onClick={handleStopRecording}
                    />
                  )}
                </div>
                {recorderState === MediaRecorderState.Complete && (
                  <div className="tvp-buttonPart">
                    <Button
                      label={<FormattedMessage id="button.Retake" />}
                      className={
                        "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-white tvp-clr-neutral tvp-bdr-green tvp-m-1"
                      }
                      onClick={handleRetake}
                    />
                    <Button
                      disabled={isSubmitDisabled}
                      label={<FormattedMessage id="button.Submit" />}
                      className={
                        isSubmitDisabled
                          ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray tvp-w-150"
                          : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-green tvp-bdr-green tvp-m-1"
                      }
                      onClick={handleSubmit}
                    />
                  </div>
                )}
              </>
            )}
          </Content>
          <FooterLogo />
        </>
      )}
    </Base>
  );
};

export default EnvTestCapture;

import { useEffect } from "react";
import { Device } from "@twilio/voice-sdk";
import { twilioAudioTokenRefresh } from "config/constant";
import { TwilioAudioCallEvent } from "globals/enums";
import { audioTokenApi } from "services/audio-token";
import { handleError } from "helpers/sentry";
import { useAppDispatch, useAppSelector } from "store/store";
import { setProctorCandidateChat, setTwilioAudioCallStatus } from "store/app.slice";

const useTwilioCall = () => {
  const dispatch = useAppDispatch();
  const sessionDetails = useAppSelector((state: any) => state.app.session);
  const [getAudioToken] = audioTokenApi.endpoints.getAudioToken.useLazyQuery();

  const handleDisconnect = (status: TwilioAudioCallEvent) => {
    dispatch(setTwilioAudioCallStatus({ twilio_audio_call_status: status }));
  };

  const startTwilioAudioCall = async (token: string) => {
    try {
      const device = new Device(token, { tokenRefreshMs: twilioAudioTokenRefresh });
      device.register();

      device.on(TwilioAudioCallEvent.Incoming, async (incomingCall) => {
        console.log("Proctor calling waiting for 3 seconds to respond");
        dispatch(
          setTwilioAudioCallStatus({
            twilio_audio_call_status: TwilioAudioCallEvent.Incoming,
            twilio_audio_call: device
          })
        );
        dispatch(setProctorCandidateChat({ showProctorCandidateChat: true }));
        setTimeout(() => {
          incomingCall.accept();
        }, 3000);
        incomingCall.on(TwilioAudioCallEvent.Disconnect, () => {
          handleDisconnect(TwilioAudioCallEvent.Disconnect);
        });
      });

      device.on(TwilioAudioCallEvent.TokenWillExpire, async () => {
        const response = await getAudioToken();
        const newToken = response.data?.token as string;
        device.updateToken(newToken);
      });

      device.on(TwilioAudioCallEvent.Error, (twilioError) => {
        console.error(twilioError);
      });
    } catch (error: any) {
      handleError(error);
    }
  };

  useEffect(() => {
    const initiateAudioCall = async () => {
      if (!sessionDetails?.session_token) {
        return;
      }

      try {
        const response = await getAudioToken();
        const token = response.data?.token as string;

        if (token) {
          startTwilioAudioCall(token);
        }
      } catch (error: any) {
        handleError(error);
      }
    };

    initiateAudioCall();
  }, [sessionDetails]);

  return [];
};

export default useTwilioCall;

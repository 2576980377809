import { RecordingService, RecordingServiceOptions } from "globals/interfaces";
import Video, { LocalTrackPublication, LocalVideoTrack } from "twilio-video";
import { store } from "store/store";
import { Twilio } from "globals/enums";
import { maxConnectionRetries, retryTimeoutInMS } from "config/constant";

export class TwilioService implements RecordingService {
  public twilioRoom;
  private options: RecordingServiceOptions = {};
  private videoTrackRef = null;
  private networkLevel;
  private retryCount = 0;

  async connect(options: RecordingServiceOptions) {
    this.options = options;
    try {
      const connect = await Video.connect(options.streamingToken, {
        audio: true,
        tracks: options.tracks || [],
        video: {
          aspectRatio: 16 / 9
        },
        bandwidthProfile: {
          video: {
            trackSwitchOffMode: "disabled"
          }
        },
        networkQuality: {
          local: Twilio.NetworkQualityLocal,
          remote: Twilio.NetworkQualityRemote
        },
        automaticSubscription: false
      });

      Video.createLocalVideoTrack().then((localVideoTrack) => {
        this.videoTrackRef = localVideoTrack;
      });

      connect.on("disconnected", () => {
        if (this.retryCount < maxConnectionRetries) {
          return this.reconnect();
        }
        console.log("Twilio Disconnected");
        this.options.onDisconnect();
      });

      connect.on("reconnecting", () => {
        this.options.onReconnecting();
      });

      connect.on("reconnected", () => {
        this.options.onReconnected();
      });

      connect.localParticipant?.on("networkQualityLevelChanged", () => {
        const isGoodQuality = this.handleNetworkQualityChange(connect);
        options.onNetworkQualityChange(isGoodQuality);
      });
      options.trackPublished(connect);
      connect.localParticipant.on("trackPublicationFailed", options.onConnectionFailure);
      this.networkLevel = connect.localParticipant.networkQualityLevel;
      this.twilioRoom = connect;
      this.retryCount = 0;
      console.log("connected", connect.localParticipant);
    } catch (e) {
      if (this.retryCount < maxConnectionRetries) {
        return this.reconnect();
      }
      options.onConnectionFailure(e);
    }
  }

  handleNetworkQualityChange = (connect) => {
    const localNetworkQuality = connect.localParticipant?.networkQualityLevel;

    if (localNetworkQuality !== undefined) {
      const isBelowThreshold = localNetworkQuality < Twilio.NetworkQualityLevelThreshold;
      const isAboveThreshold = localNetworkQuality >= Twilio.NetworkQualityLevelThreshold;
      const currentAboveThreshold = this.networkLevel >= Twilio.NetworkQualityLevelThreshold;

      if (
        (isBelowThreshold && currentAboveThreshold) ||
        (isAboveThreshold && !currentAboveThreshold)
      ) {
        this.networkLevel = localNetworkQuality;
        return isAboveThreshold;
      }
    }

    return false;
  };

  reconnect(): void {
    const isVideoStreamingEnabled = store.getState().app.isVideoStreamingEnabled;
    const isScreenRecordingEnabled = store.getState().app.isScreenRecordingEnabled;
    const isSessionActive = isVideoStreamingEnabled || isScreenRecordingEnabled;

    if (!isSessionActive) return;

    console.log(`Twilio reconnect attempt: ${this.retryCount}`);
    setTimeout(() => {
      this.connect(this.options);
    }, retryTimeoutInMS);

    this.retryCount++;
  }

  disconnect(): void {
    console.log("Twilio Disconnected");
    if (this.twilioRoom) {
      this.twilioRoom.disconnect();
      if (this.videoTrackRef) {
        try {
          this.videoTrackRef.stop();
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  createLocalVideoTrack(track: MediaStreamTrack, trackName) {
    if (track) {
      const localTrack = new LocalVideoTrack(track, {
        name: trackName
      });
      return localTrack;
    }
  }

  async publishTrack(track: LocalVideoTrack) {
    if (track) {
      try {
        const publishedTrack = await this.twilioRoom?.localParticipant?.publishTrack(track);
        return publishedTrack;
      } catch (e) {
        this.options.onConnectionFailure(e);
        return null;
      }
    }
  }

  async unpublishTrack(publishedTrack: LocalTrackPublication) {
    if (publishedTrack) {
      await publishedTrack.unpublish();
    }
  }

  async flipVideo(tracks: MediaStreamTrack[]) {
    if (!tracks?.length) {
      return;
    }
    try {
      this.twilioRoom.localParticipant.videoTracks.forEach((publication) => {
        if (publication.track && publication.track.name !== "screenshare") {
          publication.track.stop();
          publication.unpublish();
        }
      });
      this.twilioRoom.localParticipant.audioTracks.forEach((publication) => {
        if (publication.track) {
          publication.track.stop();
          publication.unpublish();
        }
      });

      const newVideoTrack = tracks.find((track) => track.kind === "video");
      const newAudioTrack = tracks.find((track) => track.kind === "audio");
      if (!newVideoTrack) {
        throw new Error("No video track found");
      }
      if (!newAudioTrack) {
        throw new Error("No audio track found");
      }
      await this.twilioRoom.localParticipant?.publishTrack(newVideoTrack, {
        name: "video"
      });
      await this.twilioRoom.localParticipant?.publishTrack(newAudioTrack, {
        name: "audio"
      });

      this.options.tracks = [newVideoTrack, newAudioTrack];
    } catch (e) {
      this.options.onConnectionFailure(e);
    }
  }
}

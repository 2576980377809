import { dequeueItem } from "store/alertSlice";
import { useCreateAlertMutation } from "services/alert";
import { AlertType, AlertName } from "helpers/alert-type";
import useQueueProcessor from "./useQueueProcessor";

const useAlertUploader = () => {
  const [createAlert] = useCreateAlertMutation();
  const queueSelector = (state: any) => state.alertQueue;

  const uploadAction = async (item: any) => {
    const keepalive = item?.alert_type_id === AlertType[AlertName.CandidateOffline].id;
    await (keepalive
      ? createAlert({ ...item, keepalive: true }).unwrap()
      : createAlert(item).unwrap());
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadAction);
};

export default useAlertUploader;

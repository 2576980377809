import { FormattedMessage } from "react-intl";
import PermissionIcon from "assets/img/permissions.png";
import { useAppDispatch, useAppSelector } from "store/store";
import Button from "components/Button";
import { updateReconnectionCount } from "store/app.slice";
import { useState } from "react";
import { maxConnectionRetries } from "config/constant";

const StreamingReconnection = () => {
  const dispatch = useAppDispatch();
  const reconnectionCount = useAppSelector(state => state.app.reconnectionCount);
  const [isRetrying, setIsRetrying] = useState(false);

  const handleReconnect = () => {
    setIsRetrying(true);
    setTimeout(() => {
      setIsRetrying(false);
    }, 3000);

    if (reconnectionCount < maxConnectionRetries) {
      dispatch(updateReconnectionCount());
    } else {
      setIsRetrying(true);
      window.location.reload();
    }
  };

  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img className="tvp-permissionIcon tvp-mt-5" src={PermissionIcon} alt="permissionIcon" />
      </div>
      <span className="tvp-mt-4">
        <h6 className="tvp-my-3 tvp-font-bold tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.streamingReconnection.header" />
        </h6>
      </span>
      <div className="tvp-w-5/6 tvp-text-center tvp-mx-auto">
        <FormattedMessage id="component.streamingReconnection.content-1" />
        <Button
          disabled={isRetrying}
          label={<FormattedMessage id="component.streamingReconnection.retryConnection.button" />}
          className={
            isRetrying
              ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray tvp-mt-5"
              : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-green tvp-mx-auto tvp-bdr-green tvp-mt-5"
          }
          onClick={handleReconnect}
        />
      </div>
    </div>
  );
};

export default StreamingReconnection;

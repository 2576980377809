import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { firebaseCollections, PauseProctorReasonCodes, StorageKeyNames } from "globals/enums";
import { getDatabase, onValue, ref } from "firebase/database";
import { showPauseProctorScreen } from "../helpers/pauseProctor";
import { useNavigate } from "react-router";
import { setIsPaused } from "../store/app.slice";
import { ProctoringRoute } from "../routes/route";
import { AlertLogConfig } from "services/session-token";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";

const useWatchPauseStatus = () => {
  const session = useAppSelector((state) => state.app.session);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const lastPauseState = useRef(null);

  useEffect(() => {
    if (!session.session_id) return;

    const pauseRef = ref(getDatabase(), `${firebaseCollections.SessionV2}/${session.session_id}`);
    const unsubscribe = onValue(pauseRef, (sessionSnapShot) => {
      const isPause = sessionSnapShot.val() || {};
      const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);

      if (currentScreen !== ProctoringRoute.RecorderScreen) return;

      if (isPause.pause !== lastPauseState.current) {
        lastPauseState.current = isPause.pause;
        if (isPause.pause) {
          dispatch(setIsPaused({ isPaused: true }));
          alertHelper(alertConfig).raiseAlert(AlertName.ProctoringSessionPaused, dispatch);
          showPauseProctorScreen(dispatch, navigate, PauseProctorReasonCodes.PausedByProctor);
        } else {
          dispatch(setIsPaused({ isPaused: false }));
          alertHelper(alertConfig).raiseAlert(AlertName.ProctoringSessionResumed, dispatch);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [session.session_id, alertConfig, navigate, dispatch]);

  return null;
};

export default useWatchPauseStatus;

import { handleError } from "helpers/sentry";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QueueItem {}

type QueueState = {
  screenGrabQueue: {
    items: QueueItem[];
  };
  cameraGrabQueue: {
    items: QueueItem[];
  };
  videoRecordingQueue: {
    items: QueueItem[];
  };
  alertQueue: {
    items: QueueItem[];
  };
  priorityAlertQueue: {
    items: QueueItem[];
  };
};

const useQueueProcessor = <T extends QueueItem>(
  queueSelector: (state: QueueState) => { items: T[] },
  dequeueAction: any,
  callback: (item: T) => Promise<any>
) => {
  const dispatch = useAppDispatch();
  const queue = useAppSelector(queueSelector);
  const isOnline = useAppSelector((state) => state.app.isOnline);
  const [processedQueueItem, setProcessedQueueItem] = useState(null);

  const processQueueItem = async () => {
    const item = queue.items[0];
    if (item !== processedQueueItem) {
      setProcessedQueueItem(item);
      try {
        await callback(item);
      } catch (error) {
        handleError(error);
      }
      dispatch(dequeueAction());
    }
  };

  useEffect(() => {
    (async () => {
      const { items } = queue;
      if (isOnline && items.length) {
        await processQueueItem();
      }
    })();
  }, [queue.items, isOnline]);
};

export default useQueueProcessor;

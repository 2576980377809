export enum SessionScope {
  AiProctor = "ai_proctor",
  CandidateAuth = "candidate_auth",
  LiveProctor = "live_proctor",
  RecordAndReview = "record_and_review"
}

export enum StreamingProvider {
  Twilio = "twilio",
  Livekit = "livekit",
  PlatformStreaming = "platform_streaming"
}

export enum MediaRecorderState {
  InActive = "inactive",
  Recording = "recording",
  Complete = "complete"
}

export enum ScreenShareEvent {
  TabScreenShare = "tab-screen-share",
  EntireScreenShare = "entire-screen-share",
  WindowScreenShare = "window-screen-share",
  Browser = "browser",
  Monitor = "monitor",
  PrimaryMonitor = "Primary Monitor",
  Window = "window",
  Screen = "screen",
  NotShared = "not shared"
}

export enum BrowserName {
  Chrome = "chrome",
  Edge = "microsoft edge",
  Firefox = "firefox",
  Safari = "safari"
}

export enum ScreenShareStatus {
  InitializePermission = "initialize permission",
  ScreenShareStarted = "screen share started",
  ScreenShareStopped = "screen share stopped",
  PermissionFailed = "permission failed",
  ReInitializePermission = "Re-initialize permission"
}

export enum FullScreenState {
  NotStarted = "not started",
  Started = "started",
  Stopped = "stopped",
  Error = "error"
}

export enum ReadyState {
  Live = "live",
  Ended = "ended"
}

export enum TwilioAudioCallEvent {
  Incoming = "incoming",
  TokenWillExpire = "tokenWillExpire",
  Disconnect = "disconnect",
  Cancel = "cancel",
  Ignore = "ignore",
  Accept = "accept",
  Requested = "requested",
  Error = "error"
}

export enum Role {
  Candidate = "Candidate",
  Proctor = "Proctor",
  Alvy = "Alvy"
}

export enum StorageKeyNames {
  Screen = "screen",
  MixPanelData = "mixPanelData"
}

export enum ErrorMessage {
  NoFaceDetected = "No face has been detected. Please try again with another image.",
  MultipleFacesDetected = "Multiple faces have been detected. Please try again with another image."
}

export enum PhotoIdFileSource {
  ImageCaptured = "image_captured",
  IdCardUpload = "id_card_upload"
}

export enum FeatureFlags {
  AudioCall = "audio_call",
  AudioCheck = "audio_check",
  ChatSupportEvent = "chat_support_event",
  ChatSupportPrecheck = "chat_support_precheck",
  EnvironmentCheck = "environment_check",
  EyeGaze = "eye_gaze",
  FaceCapture = "face_capture",
  HighTouchProctoring = "high_touch_proctoring",
  IdVerificationCheck = "id_verification_check",
  MidwayFaceVerification = "midway_face_verification",
  MixpanelAnalytics = "mixpanel_analytics",
  ObjectDetection = "object_detection",
  DisableNoiseDetection = "disable_noise_detection",
  OrganizationId = "organization_id",
  PreFlightApproval = "pre_flight_approval",
  ScreenGrab = "screen_grab",
  TermsAndConditions = "terms_and_conditions",
  TwilioStreaming = "twilio_streaming",
  VideoRecording = "video_recording",
  StartBufferTime = "start_buffer_time",
  EndBufferTime = "end_buffer_time",
  Type = "type",
  ValidFrom = "valid_from",
  ValidTill = "valid_till",
  Firebase = "firebase_db",
  ProctorInitiatedAudioCall = "proctor_initiated_audio_call",
  ScreenRecording = "screen_recording",
  FullScreenMode = "full_screen_mode",
  SecondaryCamera = "secondary_camera",
  BrandColor = "brand_color",
  BrandLogo = "brand_logo",
  TermsAndConditionsURL = "terms_and_condition_url",
  PrivacyURL = "privacy_url",
  SupportURL = "support_url",
  SupportEmail = "support_email",
  StreamingProvider = "streaming_provider",
  Feedback = "feedback",
}

export enum AppLanguage {
  English = "English",
  French = "French",
  Japanese = "Japanese",
  Welsh = "Welsh",
  Chinese = "Chinese",
  BrazilianPortuguese = "Brazilian Portuguese",
  Spanish = "Spanish"
}

export enum AppLocale {
  English = "en-US",
  French = "fr-FR",
  Japanese = "ja-JA",
  Welsh = "cy-GB",
  Chinese = "zh-CN",
  BrazilianPortuguese = "pt-BR",
  Spanish = "es-ES"
}

export enum Environment {
  Local = "local",
  Development = "development",
  Staging = "staging",
  Production = "production",
  Testing = "testing"
}

export enum ErrorTypes {
  ProviewLoadingError = "Proview-loading-error",
  NoShowError = "no-show-error",
  ScreenShareError = "screen-share-error",
  TerminatedError = "terminated-error",
  NetworkConnectivityErrDetails = "network-connectivity-error",
  CameraInaccessibleError = "camera-inaccessible-error",
  MicrophoneInaccessibleError = "microphone-inaccessible-error",
  NotificationsInaccessibleError = "notifications-inaccessible-error",
  CameraNotDetected = "camera-not-detected",
  BrowserNotSupported = "browser-not-supported",
  SessionCompleted = "session-completed",
  SlotTimeAlreadyPassed = "slot-time-already-passed",
  TestNotStarted = "test-not-started",
  SessionNotScheduled = "session-not-scheduled",
  SessionExpired = "session-expired",
  MediaStreamError = "media-stream-error",
  StreamingDisonnection = "streaming-disconnection",
  StreamingReconnection = "streaming-reconnection"
}

export enum PrecheckState {
  Approved = "approved",
  Submitted = "submitted",
  Rejected = "rejected",
  Pending = "pending"
}

export enum VerificationType {
  PhotoId = "photo_id",
  TestEnv = "test_env"
}

export enum ErrorTypesMessages {
  SessionTerminationError = "Session has been terminated",
  NoShowError = "Session is already marked as no-show",
  SessionCompletedError = "Session has been completed",
  SlotTimeAlreadyPassed = "You are not authorized to perform this operation as the slot time has already passed"
}

export enum MediaStreamErrorNames {
  TrackFailedError = "TrackFailedError",
  AudioHardwareFailedError = "AudioHardwareFailedError",
  AudioHardwareFailedMessage = "Audio device failed in recorder-screen",
  VideoHardwareFailedError = "VideoHardwareFailedError"
}

export enum SessionStatus {
  NoShow = "no_show",
  Terminated = "terminated",
  Completed = "completed",
  Online = "online",
  Offline = "offline"
}

export enum AccessStatus {
  Granted = "granted",
  Denied = "denied"
}

export enum SecondaryDeviceStatus {
  Disconnected = "disconnected",
  Connected = "connected",
  InActive = "inactive"
}

export enum Twilio {
  NetworkQualityLevelThreshold = 3,
  NetworkQualityLocal = 1,
  NetworkQualityRemote = 0
}

export enum ChatSupportOptions {
  ChatWithProctor = "Chat with Proctor",
  ChatWithTechTeam = "Chat with Tech Team"
}

export enum PauseProctorReasonCodes {
  NetworkDisconnection = "TV-PC-NDC"
}

export enum AudioCallStatus {
  Requested = "Audio Call Requested"
}

export enum firebaseCollections {
  AudioCall = "audio_call",
  SessionV2 = "session_v2",
  Session = "session",
  RoomParticipant= "room_participant"
}

export enum CallStatus {
  Stale = "stale",
  Initiated = "initiated",
  Requested = "requested",
  Connected = "connected",
  Disconnected = "disconnected"
}

export enum VideoProvider {
  TWILIO = "twilio",
  LIVEKIT = "livekit",
  PLATFORMSTREAM = "platform_streaming"
}

export enum ChatType {
  RECORDING = "recording",
  TEXT = "text"
}

export enum SubTitleMode {
  Showing = "showing",
  Hidden = "hidden"
}

import { dequeueItem } from "../store/screenGrabSlice";
import { useCreateBlobMutation } from "services/blobStorage";
import useQueueProcessor from "./useQueueProcessor";

const useScreenGrabUploader = () => {
  const [createBlob] = useCreateBlobMutation();
  const queueSelector = (state: any) => state.screenGrabQueue;

  const uploadAction = async (item: any) => {
    createBlob(item);
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadAction);
};

export default useScreenGrabUploader;

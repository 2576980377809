import { MediaSourceInputPayload } from "globals/interfaces";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { detectMob, getUserBrowser, isTsbBrowser } from "helpers/browser-helper";
import { handleError } from "helpers/sentry";
import { useSelector } from "react-redux";
import {
  setAudioSourceInput,
  setMediaPermission,
  setUiErrorScreen,
  setVideoSourceInput
} from "../store/app.slice";
import { BrowserName, ErrorTypes, SessionStatus } from "../globals/enums";
import { alertHelper } from "../helpers/alert-helper";
import { AlertName } from "../helpers/alert-type";

interface MediaContextProps {
  videoStream: MediaStream | null;
  audioStream: MediaStream | null;
  displayStream: MediaStream | null;
  mediaStream: MediaStream | null;
  mediaStreamError: any;
  displayMediaStreamError: any;
  start: (mediaConstraintsObject?: MediaStreamConstraints) => void;
  stop: () => Promise<void>;
  stopDisplayMedia: () => void;
  setMediaStreamError: any;
  startDisplayMedia: (mediaOptions?: ExtendedMediaStreamConstraints) => Promise<void>;
}

interface ExtendedMediaStreamConstraints extends MediaStreamConstraints {
  video?:
    | boolean
    | (MediaTrackConstraints & {
        mandatory?: { chromeMediaSource: "desktop" };
        mediaSource?: string;
      });
}

export type MediaPermissionsError = {
  type?: MediaPermissionsErrorType;
  name: string;
  message?: string;
};

export enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = "SystemPermissionDenied",
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = "UserPermissionDenied",
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = "CouldNotStartVideoSource",
  /** Device not found */
  DeviceNotFound = "DeviceNotFound",
  /** all other errors */
  Generic = "Generic"
}

const MediaContext = createContext<MediaContextProps>({
  videoStream: null,
  audioStream: null,
  displayStream: null,
  mediaStream: null,
  mediaStreamError: null,
  displayMediaStreamError: null,
  start: () => {},
  stop: async () => {},
  stopDisplayMedia: () => {},
  setMediaStreamError: () => {},
  startDisplayMedia: async () => {}
});

const MediaProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [displayStream, setDisplayStream] = useState<MediaStream | null>(null);
  const [mediaStreamError, setMediaStreamError] = useState<any>(null);
  const [displayMediaStreamError, setDisplayMediaStreamError] = useState<any>(null);
  const audioSourceInput: MediaSourceInputPayload = useAppSelector(
    (state) => state.app.audioSourceInput
  );
  const errorScreenName = useAppSelector((state: RootState) => state.app.uiErrorScreen);

  const videoSourceInput: MediaSourceInputPayload = useAppSelector(
    (state) => state.app.videoSourceInput
  );
  const {
    configuration: { face_capture, video_recording, object_detection, midway_face_verification },
    precheckSuccess,
    alertConfigs,
    sessionStatus
  } = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();
  const browser = getUserBrowser();

  const getMediaStreamTracks = async (newMediaConstraints) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(newMediaConstraints);
      if (stream) {
        dispatch(
          setMediaPermission({
            mediaPermission: {
              video: !!newMediaConstraints.video,
              audio: !!newMediaConstraints.audio
            }
          })
        );
        console.log("Media stream started");
        const videoTracks = stream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();

        videoTracks.forEach((videoTrack) => {
          videoTrack.onended = getVideoErrorHandler;

          videoTrack.addEventListener("mute", () => {
            console.log("Video track muted");
            alertHelper(alertConfigs).raiseAlert(AlertName.VideoStreamMute, dispatch);
          });
          videoTrack.addEventListener("unmute", () => {
            console.log("Audio track unmuted");
            alertHelper(alertConfigs).raiseAlert(AlertName.VideoStreamUnmute, dispatch);
          });
        });

        audioTracks.forEach((audioTrack) => {
          audioTrack.onended = getAudioErrorHandler;

          audioTrack.addEventListener("mute", () => {
            console.log("Audio track muted");
            alertHelper(alertConfigs).raiseAlert(AlertName.AudioStreamMute, dispatch);
          });
          audioTrack.addEventListener("unmute", () => {
            console.log("Audio track unmuted");
            alertHelper(alertConfigs).raiseAlert(AlertName.AudioStreamUnmute, dispatch);
          });
        });

        return { stream, videoTracks, audioTracks };
      }
    } catch (err) {
      dispatch(
        setMediaPermission({
          mediaPermission: {
            video: false,
            audio: false
          }
        })
      );
      setMediaStreamError(handleMultiBrowserError(err));
    }
  };

  const handleStopScreenSharing = () => {
    const screenshareStop = new CustomEvent("StopScreenShare");
    document.dispatchEvent(screenshareStop);
  };

  const getVideoErrorHandler = async () => {
    if (sessionStatus !== SessionStatus.Completed) {
      if (!precheckSuccess && browser === BrowserName.Firefox) {
        dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.CameraInaccessibleError }));
      } else {
        handleError("Video Track ended : error");
        dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.MediaStreamError }));
      }
      setMediaStreamError(new DOMException("Video Track ended", "TrackFailedError"));
    }
    alertHelper(alertConfigs).raiseAlert(AlertName.MediaTrackEnded, dispatch);
    handleStopScreenSharing();
  };

  const getAudioErrorHandler = async () => {
    if (sessionStatus !== SessionStatus.Completed) {
      if (!precheckSuccess && browser === BrowserName.Firefox) {
        dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.MicrophoneInaccessibleError }));
      } else {
        handleError("Audio Track ended : error");
        dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.MediaStreamError }));
      }
      setMediaStreamError(new DOMException("Audio Track ended", "TrackFailedError"));
    }
    alertHelper(alertConfigs).raiseAlert(AlertName.MediaTrackEnded, dispatch);
    handleStopScreenSharing();
  };

  const handleMultiBrowserError = (err) => {
    const errName = err.name;
    const errMessage = err.message;
    let errorType: MediaPermissionsErrorType = MediaPermissionsErrorType.Generic;
    if (browser === BrowserName.Chrome) {
      if (errName === "NotAllowedError") {
        if (errMessage === "Permission denied by system") {
          errorType = MediaPermissionsErrorType.SystemPermissionDenied;
        } else if (errMessage === "Permission denied") {
          errorType = MediaPermissionsErrorType.UserPermissionDenied;
        }
      } else if (errName === "NotReadableError") {
        errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
      } else if (errName === "NotFoundError") {
        errorType = MediaPermissionsErrorType.DeviceNotFound;
      }
    }
    // else if (browser === "safari") {
    //   if (errName === "NotAllowedError") {
    //     errorType = MediaPermissionsErrorType.UserPermissionDenied;
    //   }
    // }
    else if (browser === BrowserName.Edge) {
      if (errName === "NotAllowedError") {
        errorType = MediaPermissionsErrorType.UserPermissionDenied;
      } else if (errName === "NotReadableError") {
        errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
      }
    } else if (browser === BrowserName.Firefox) {
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
      if (errName === "NotFoundError") {
        errorType = MediaPermissionsErrorType.SystemPermissionDenied;
      } else if (errName === "NotReadableError") {
        errorType = MediaPermissionsErrorType.SystemPermissionDenied;
      } else if (errName === "NotAllowedError") {
        errorType = MediaPermissionsErrorType.UserPermissionDenied;
      } else if (errName === "AbortError") {
        errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
      }
    }
    return errorType;
  };

  const getCamera = (devices) => {
    if (detectMob()) {
      return devices.filter(
        (device) => device.kind === "videoinput" && device.label.includes("front")
      );
    } else {
      return devices.filter((device) => device.kind === "videoinput");
    }
  };

  const start = async (mediaConstraintsObject?: MediaStreamConstraints) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphones = devices.filter((device) => device.kind === "audioinput");
      const camera = getCamera(devices);
      if (!audioSourceInput.deviceId) {
        dispatch(setAudioSourceInput({ audio_source: microphones[0] }));
      }
      if (!videoSourceInput.deviceId) {
        dispatch(setVideoSourceInput({ video_source: camera[0] }));
      }
      const mediaConstraints: MediaStreamConstraints = {
        audio: {
          deviceId: audioSourceInput.deviceId
            ? { exact: audioSourceInput.deviceId }
            : microphones[0]?.deviceId
        },
        video: {
          width: 720,
          height: 540,
          deviceId: videoSourceInput.deviceId
            ? { exact: videoSourceInput.deviceId }
            : camera[0]?.deviceId
        }
      };

      const newMediaConstraints = {
        ...mediaConstraints,
        audio: mediaConstraintsObject?.audio
          ? mediaConstraintsObject.audio
          : mediaConstraints.audio,
        video:
          face_capture || video_recording || object_detection || midway_face_verification
            ? mediaConstraintsObject?.video
              ? mediaConstraintsObject.video
              : mediaConstraints.video
            : false
      };

      if (errorScreenName !== ErrorTypes.SessionExpired) {
        const mediaStreamTracks = await getMediaStreamTracks(newMediaConstraints);
        if (mediaStreamTracks) {
          const { stream, videoTracks, audioTracks } = mediaStreamTracks;
          const videoStream = new MediaStream(videoTracks);
          const audioStream = new MediaStream(audioTracks);

          setMediaStream(stream);
          setVideoStream(videoStream);
          setAudioStream(audioStream);
        }
      }
    } catch (error) {
      handleError(error);
      setMediaStreamError(error);
      console.error("Error starting media stream");
    }
  };

  const stop = async () => {
    if (mediaStream) {
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => track.stop());
      setMediaStream(null);
    }
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    }
    if (audioStream) {
      const audioTracks = audioStream.getTracks();
      audioTracks.forEach((track) => track.stop());
      setAudioStream(null);
    }
    console.log("Media stream stopped");
  };

  const stopDisplayMedia = () => {
    if (displayStream) {
      const displayTracks = displayStream.getTracks();
      displayTracks.forEach((track) => track.stop());
      setDisplayStream(null);
    }
    console.log("Display Media stream stopped");
  };

  const getDisplayMediaStream = async (
    mediaOptions: MediaStreamConstraints | ExtendedMediaStreamConstraints | undefined
  ) => {
    if (!isTsbBrowser()) {
      return await navigator.mediaDevices.getDisplayMedia(mediaOptions);
    }
    const tsbMediaConstrains: ExtendedMediaStreamConstraints = {
      audio: false,
      video: {
        mandatory: {
          chromeMediaSource: "desktop"
        }
      }
    };
    return await navigator.mediaDevices.getUserMedia(tsbMediaConstrains);
  };

  const startDisplayMedia = async (
    mediaOptions?: MediaStreamConstraints | ExtendedMediaStreamConstraints | any
  ) => {
    try {
      const stream = await getDisplayMediaStream(mediaOptions);
      console.log("Display media stream started");
      dispatch(
        setMediaPermission({
          mediaPermission: {
            screen: true
          }
        })
      );
      setDisplayStream(stream);
    } catch (error) {
      setDisplayMediaStreamError(error);
      console.error("Error starting display media stream");
    }
  };

  useEffect(() => {
    if (
      errorScreenName === ErrorTypes.SessionExpired &&
      (mediaStream || videoStream || audioStream)
    ) {
      stop();
    }
  }, [errorScreenName, mediaStream, videoStream, audioStream]);

  return (
    <MediaContext.Provider
      value={{
        videoStream,
        mediaStream,
        audioStream,
        displayStream,
        mediaStreamError,
        displayMediaStreamError,
        start,
        stop,
        startDisplayMedia,
        setMediaStreamError,
        stopDisplayMedia
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export { MediaContext, MediaProvider };

import { dequeueItem } from "store/priorityAlertSlice";
import { useCreateAlertMutation } from "services/alert";
import useQueueProcessor from "./useQueueProcessor";

const useAlertUploader = () => {
  const [createAlert] = useCreateAlertMutation();
  const queueSelector = (state: any) => state.priorityAlertQueue;

  const uploadAction = async (item: any) => {
    await createAlert(item).unwrap();
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadAction);
};

export default useAlertUploader;

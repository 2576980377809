export enum AlertName {
  AudioCallRequested = "Audio Call Requested",
  AudioTestPassed = "Audio Test Passed",
  AudioDeviceDetected = "Audio device detected",
  AudioDeviceNotDetected = "Audio device not detected",
  BrowserNotSupported = "Browser Not Supported",
  CameraGrab = "Camera Grab",
  CameraDetected = "Camera Detected",
  CameraNotDetected = "Camera Not Detected",
  CameraSwitched = "Camera switched",
  CameraTestFailed = "Camera Test Failed",
  CameraTestPassed = "Camera Test Passed",
  CandidateOffline = "Candidate Offline",
  CandidateOnline = "Candidate Online",
  FaceCapture = "Face capture",
  FaceCaptureSuccess = "Face capture Success",
  FaceCaptureFailure = "Face capture Failure",
  FaceDetected = "Face Detected",
  FaceNotDetected = "Face Not Detected",
  MicrophoneTestFailed = "Microphone Test Failed",
  MicrophoneTestPassed = "Microphone Test Passed",
  MicrophoneSwitched = "Microphone switched",
  MultipleFaceDetected = "Multiple Face Detected",
  NotificationPermissionDenied = "Notification Permission Denied",
  NotificationPermissionGranted = "Notification Permission Granted",
  PartialFaceDetected = "Partial Face Detected",
  PastingTextDetected = "Pasting text detected",
  PhotoIdCapture = "Photo ID capture",
  RecordingStarted = "Recording Started",
  RecordingStopped = "Recording Stopped",
  ScreenCaptureDenied = "Screen Capture Denied",
  ScreenCaptureStarted = "Screen-capture Started",
  ScreenCaptureStopped = "Screen-capture Stopped",
  ScreenCaptureEnabled = "Screen Capture Enabled",
  SessionCompleted = "Session Completed",
  SessionJoined = "Session Joined",
  TestEnvCheck = "Test Environment Check",
  UserActive = "User Active",
  UserIdle = "User Idle",
  WindowFocusIn = "Window Focus-In",
  WindowFocusOut = "Window Focus-Out",
  IdDCardCaptureFailure = "ID card capture failure",
  IdCardCaptureSuccess = "ID card capture success",
  EnvironmentTestCaptureSuccess = "Environment test capture success",
  EnvironmentTestCaptureFailure = "Environment test capture failure",
  FileUploadFailure = "File Upload Failure",
  CandidateLowBandwidth = "Candidate on Low Bandwidth",
  CandidateGoodBandwidth = "Candidate on Good Bandwidth",
  VideoStreamReconnect = "Video stream reconnect",
  PrintScreenClicked = "Print Screen Clicked",
  MobilePhoneDetected = "Mobile like object detected",
  BookDetected = "Book like object detected",
  UserClosedRefreshed = "User Closed/refreshed the proctoring session",
  MultiplePeopleDetected = "Multiple People Detected",
  MultipleMonitorDetected = "Multiple monitors detected",
  NoiseDetected = "Noise detected(beta)",
  EnterFullscreen = "Entered full screen",
  ExitFullscreen = "Exited full screen",
  VideoStreamMute = "Video stream muted",
  VideoStreamUnmute = "Video stream un-muted",
  AudioStreamMute = "Audio stream muted",
  AudioStreamUnmute = "Audio stream un-muted",
  MediaTrackEnded = "Media stream track ended",
  InstructionsProceeded = "Instructions proceeded",
  FeedbackRatingSkipped = "Feedback rating skipped",
  FeedbackRatingSubmitted = "Feedback rating submitted",
  ProctorApprovalCompleted = "Proctor approval completed",
  TermsConditionsProceeded = "Terms conditions proceeded",
  HardwarePermissionsGranted = "Hardware permissions granted",
  ProctoringSessionPaused = "Proctoring Session Paused",
  ProctoringSessionResumed = "Proctoring Session Resumed",
  ProctoringSessionExtended = "Proctoring Session Extended",
  RestrictedURLDetected = "Restricted URL Visited",
  NewURLDetected = "New URL Visited"
}

export enum AlertDisplayType {
  Info = "info",
  Error = "error",
  None = "none"
}

export enum AlertDescription {
  FaceNotDetected = "Ensure your face is visible throughout the test",
  MultipleFaceDetected = "Please make sure to take the test privately",
  MobilePhoneDetected = "Please make sure not to use mobile phone",
  BookDetected = "Please make sure not to use a book",
  PastingTextDetected = "Kindly refrain from pasting text to the test window",
  PrintScreenClicked = "Kindly refrain from printing screen",
  WindowFocusOut = "You have switched the tab Kindly refrain from doing this action again",
  RecordingStarted = "The screen is being recorded",
  MultiplePeopleDetected = "Please ensure there is no one else around you during the session",
  None = ""
}

export const AlertType: Record<AlertName, any> = {
  [AlertName.AudioCallRequested]: {
    id: 172,
    alertName: AlertName.AudioCallRequested,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.AudioTestPassed]: {
    id: 34,
    alertName: AlertName.AudioTestPassed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.AudioDeviceDetected]: {
    id: 159,
    alertName: AlertName.AudioDeviceDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.AudioDeviceNotDetected]: {
    id: 160,
    alertName: AlertName.AudioDeviceNotDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.BrowserNotSupported]: {
    id: 57,
    alertName: AlertName.BrowserNotSupported,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CameraGrab]: {
    id: 143,
    alertName: AlertName.CameraGrab,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CameraDetected]: {
    id: 60,
    alertName: AlertName.CameraDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CameraNotDetected]: {
    id: 59,
    alertName: AlertName.CameraNotDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CameraSwitched]: {
    id: 130,
    alertName: AlertName.CameraSwitched,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CameraTestFailed]: {
    id: 27,
    alertName: AlertName.CameraTestFailed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CameraTestPassed]: {
    id: 26,
    alertName: AlertName.CameraTestPassed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CandidateOffline]: {
    id: 181,
    alertName: AlertName.CandidateOffline,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FaceCapture]: {
    id: 146,
    alertName: AlertName.FaceCapture,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FaceCaptureSuccess]: {
    id: 154,
    alertName: AlertName.FaceCaptureSuccess,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FaceCaptureFailure]: {
    id: 155,
    alertName: AlertName.FaceCaptureFailure,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FaceDetected]: {
    id: 15,
    alertName: AlertName.FaceDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FaceNotDetected]: {
    id: 16,
    alertName: AlertName.FaceNotDetected,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.FaceNotDetected
  },
  [AlertName.MobilePhoneDetected]: {
    id: 169,
    alertName: AlertName.MobilePhoneDetected,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.MobilePhoneDetected
  },
  [AlertName.BookDetected]: {
    id: 170,
    alertName: AlertName.BookDetected,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.BookDetected
  },
  [AlertName.MicrophoneTestFailed]: {
    id: 29,
    alertName: AlertName.MicrophoneTestFailed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.MicrophoneTestPassed]: {
    id: 28,
    alertName: AlertName.MicrophoneTestPassed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.MicrophoneSwitched]: {
    id: 131,
    alertName: AlertName.MicrophoneSwitched,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.MultipleFaceDetected]: {
    id: 81,
    alertName: AlertName.MultipleFaceDetected,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.MultipleFaceDetected
  },
  [AlertName.NotificationPermissionDenied]: {
    id: 18,
    alertName: AlertName.NotificationPermissionDenied,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.NotificationPermissionGranted]: {
    id: 17,
    alertName: AlertName.NotificationPermissionGranted,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.PastingTextDetected]: {
    id: 128,
    alertName: AlertName.PastingTextDetected,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.PastingTextDetected
  },
  [AlertName.PhotoIdCapture]: {
    id: 145,
    alertName: AlertName.PhotoIdCapture,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.RecordingStarted]: {
    id: 13,
    alertName: AlertName.RecordingStarted,
    displayType: AlertDisplayType.Info,
    description: AlertDescription.RecordingStarted
  },
  [AlertName.RecordingStopped]: {
    id: 14,
    alertName: AlertName.RecordingStopped,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ScreenCaptureDenied]: {
    id: 150,
    alertName: AlertName.ScreenCaptureDenied,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ScreenCaptureStarted]: {
    id: 11,
    alertName: AlertName.ScreenCaptureStarted,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ScreenCaptureStopped]: {
    id: 12,
    alertName: AlertName.ScreenCaptureStopped,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ScreenCaptureEnabled]: {
    id: 149,
    alertName: AlertName.ScreenCaptureEnabled,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.SessionCompleted]: {
    id: 113,
    alertName: AlertName.SessionCompleted,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.SessionJoined]: {
    id: 3,
    alertName: AlertName.SessionJoined,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.TestEnvCheck]: {
    id: 133,
    alertName: AlertName.TestEnvCheck,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.UserActive]: {
    id: 7,
    alertName: AlertName.UserActive,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.UserIdle]: {
    id: 6,
    alertName: AlertName.UserIdle,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.WindowFocusIn]: {
    id: 4,
    alertName: AlertName.WindowFocusIn,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.WindowFocusOut]: {
    id: 5,
    alertName: AlertName.WindowFocusOut,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.WindowFocusOut
  },
  [AlertName.IdDCardCaptureFailure]: {
    id: 157,
    alertName: AlertName.IdDCardCaptureFailure,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.IdCardCaptureSuccess]: {
    id: 156,
    alertName: AlertName.IdCardCaptureSuccess,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.EnvironmentTestCaptureSuccess]: {
    id: 161,
    alertName: AlertName.EnvironmentTestCaptureSuccess,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.EnvironmentTestCaptureFailure]: {
    id: 162,
    alertName: AlertName.EnvironmentTestCaptureFailure,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FileUploadFailure]: {
    id: 179,
    alertName: AlertName.FileUploadFailure,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CandidateGoodBandwidth]: {
    id: 191,
    alertName: AlertName.CandidateGoodBandwidth,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CandidateLowBandwidth]: {
    id: 192,
    alertName: AlertName.CandidateLowBandwidth,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.CandidateOnline]: {
    id: 193,
    alertName: AlertName.CandidateOnline,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.UserClosedRefreshed]: {
    id: 194,
    alertName: AlertName.UserClosedRefreshed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.PrintScreenClicked]: {
    id: 85,
    alertName: AlertName.PrintScreenClicked,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.PrintScreenClicked
  },
  [AlertName.MultiplePeopleDetected]: {
    id: 202,
    alertName: AlertName.MultiplePeopleDetected,
    displayType: AlertDisplayType.Error,
    description: AlertDescription.MultiplePeopleDetected
  },
  [AlertName.MultipleMonitorDetected]: {
    id: 207,
    alertName: AlertName.MultipleMonitorDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.NoiseDetected]: {
    id: 210,
    alertName: AlertName.NoiseDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.EnterFullscreen]: {
    id: 208,
    alertName: AlertName.EnterFullscreen,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ExitFullscreen]: {
    id: 209,
    alertName: AlertName.ExitFullscreen,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.VideoStreamMute]: {
    id: 218,
    alertName: AlertName.VideoStreamMute,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.VideoStreamUnmute]: {
    id: 219,
    alertName: AlertName.VideoStreamUnmute,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.AudioStreamMute]: {
    id: 220,
    alertName: AlertName.AudioStreamMute,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.AudioStreamUnmute]: {
    id: 221,
    alertName: AlertName.AudioStreamUnmute,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.MediaTrackEnded]: {
    id: 222,
    alertName: AlertName.MediaTrackEnded,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.VideoStreamReconnect]: {
    id: 223,
    alertName: AlertName.VideoStreamReconnect,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.PartialFaceDetected]: {
    id: 226,
    alertName: AlertName.PartialFaceDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.InstructionsProceeded]: {
    id: 248,
    alertName: AlertName.InstructionsProceeded,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ProctorApprovalCompleted]: {
    id: 249,
    alertName: AlertName.ProctorApprovalCompleted,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FeedbackRatingSubmitted]: {
    id: 250,
    alertName: AlertName.FeedbackRatingSubmitted,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.FeedbackRatingSkipped]: {
    id: 251,
    alertName: AlertName.FeedbackRatingSkipped,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.TermsConditionsProceeded]: {
    id: 252,
    alertName: AlertName.TermsConditionsProceeded,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.HardwarePermissionsGranted]: {
    id: 253,
    alertName: AlertName.HardwarePermissionsGranted,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ProctoringSessionPaused]: {
    id: 254,
    alertName: AlertName.ProctoringSessionPaused,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ProctoringSessionResumed]: {
    id: 255,
    alertName: AlertName.ProctoringSessionResumed,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.ProctoringSessionExtended]: {
    id: 256,
    alertName: AlertName.ProctoringSessionExtended,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.RestrictedURLDetected]: {
    id: 257,
    alertName: AlertName.RestrictedURLDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  },
  [AlertName.NewURLDetected]: {
    id: 258,
    alertName: AlertName.NewURLDetected,
    displayType: AlertDisplayType.None,
    description: AlertDescription.None
  }
};

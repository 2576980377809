import useTwilioCall from "./twilio/useTwilioCall";
import useAlertUploader from "./useAlertUploader";
import useCameraGrab from "./useCameraGrab";
import useCameraGrabUploader from "./useCameraGrabUploader";
import useDetectPasteText from "./useDetectPasteText";
import useDetectTabSwitch from "./useDetectTabSwitch";
import useFaceObjectDetection from "./useFaceObjectDetection";
import useScreenGrabUploader from "./useScreenGrabUploader";
import useVideoRecordingUploader from "./useVideoRecordingUploader";
import usePriorityAlertUploader from "./usePriorityAlertUploader";
import useWatchNetworkStatus from "./useWatchNetworkStatus";
import useScreenShareFullScreenCheck from "./useScreenShareFullScreenCheck";
import useScreenGrab from "./useScreenGrab";
import useSecondaryDevice from "./useSecondaryDevice";
import useDetectPrintScreen from "./useDetectPrintScreen";
import useVideoRecording from "./useVideoRecording";
import useTabCloseOrRefresh from "./useBeforeUnload";
import useDetectMultiMonitor from "./useDetectMultiMonitor";
import useNoiseDetection from "./useNoiseDetection";
import useRecorder from "./useRecorder";

const useRegister = () => {
  useAlertUploader();
  useCameraGrab();
  useFaceObjectDetection();
  useDetectPasteText();
  useDetectTabSwitch();
  useRecorder();
  useVideoRecording();
  useScreenGrabUploader();
  useCameraGrabUploader();
  useVideoRecordingUploader();
  useTwilioCall();
  useWatchNetworkStatus();
  useScreenShareFullScreenCheck();
  useScreenGrab();
  useSecondaryDevice();
  usePriorityAlertUploader();
  useDetectPrintScreen();
  useTabCloseOrRefresh();
  useNoiseDetection();
  useDetectMultiMonitor();
};

export default useRegister;

import { FormattedMessage } from "react-intl";
import PermissionIcon from "assets/img/permissions.png";
import arrow from "assets/img/arrow.svg";

const StreamingDisconnection = () => {
  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img className="tvp-permissionIcon tvp-mt-5" src={PermissionIcon} alt="permissionIcon" />
      </div>
      <span className="tvp-mt-4">
        <h6 className="tvp-my-3 tvp-font-bold tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.streamingDisconnection.header" />
        </h6>
      </span>
      <div className="tvp-w-5/6 tvp-mx-auto">
        <ul className="tvp-list-disc">
          <li>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt="Arrow indicating instruction one"
              />
            </div>
            <FormattedMessage id="component.streamingDisconnection.content-1" />
          </li>
          <li>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt="Arrow indicating instruction two"
              />
            </div>
            <FormattedMessage id="component.streamingDisconnection.content-2" />
          </li>
          <li>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt="Arrow indicating instruction three"
              />
            </div>
            <FormattedMessage id="component.streamingDisconnection.content-3" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StreamingDisconnection;
import { useContext, useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import useMediaRecorder from "hooks/useMediaRecorder";
import { MediaRecorderState, SessionScope, StreamingProvider } from "globals/enums";
import { enqueueItem } from "store/videoRecordingSlice";
import { setCurrentOfflineEvent } from "store/app.slice";
import { MediaContext } from "context/MediaContext";

const useVideoRecording = () => {
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.app.configuration);
  const session = useAppSelector((state) => state.app.session);
  const streamingStoppedWhenOnline = useAppSelector(
    (state) => state.app.streamingStoppedWhenOnline
  );
  const { currentOfflineEvent } = useAppSelector((state) => state.app);
  const { mediaStream, setMediaStreamError } = useContext(MediaContext);
  const [recorder, data, startTime, endTime, sequenceId, mediaStreamErrorScreen] = useMediaRecorder(
    mediaStream,
    null,
    9
  );
  const isOnline = useAppSelector((state) => state.app.isOnline);
  const isVideoStreamingEnabled = useAppSelector(
    (state: RootState) => state.app.isVideoStreamingEnabled
  );
  const isCandidateAuthSession = () => session.session_type === SessionScope.CandidateAuth;
  const isPlatformStreamingDisabled = () =>
    configuration.streaming_provider !== StreamingProvider.PlatformStreaming;

  useEffect(() => {
    if (mediaStreamErrorScreen) {
      setMediaStreamError(mediaStreamErrorScreen);
    }
    return () => {
      setMediaStreamError(null);
    };
  }, [mediaStreamErrorScreen]);

  useEffect(() => {
    if (!isCandidateAuthSession() && !isPlatformStreamingDisabled() && isVideoStreamingEnabled) {
      if (data) {
        console.log("Video Recording", data, startTime, endTime);
        dispatch(enqueueItem({ blob: data, startTime: startTime! }));
      }

      try {
        if (mediaStream && recorder.state === MediaRecorderState.InActive) {
          recorder?.start();
        }
      } catch (error) {
        console.error("MediaStream failed");
        setMediaStreamError(new DOMException("Mediastream failed", "TrackFailedError"));
      }
    }
  }, [recorder, data, isVideoStreamingEnabled]);

  useEffect(() => {
    if (
      isPlatformStreamingDisabled() &&
      (!isOnline || streamingStoppedWhenOnline) &&
      currentOfflineEvent?.offlineBatchId &&
      isVideoStreamingEnabled
    ) {
      if (data) {
        dispatch(
          enqueueItem({
            blob: data,
            offlineBatchId: currentOfflineEvent.offlineBatchId,
            offlineSequenceId: sequenceId,
            offlineTime: currentOfflineEvent.offlineTime
          })
        );
        dispatch(
          setCurrentOfflineEvent({
            offlineTime: currentOfflineEvent.offlineTime,
            offlineBatchId: currentOfflineEvent.offlineBatchId,
            totalChunksQueued: currentOfflineEvent.totalChunksQueued + 1
          })
        );
      }
      try {
        if (mediaStream && recorder.state === MediaRecorderState.InActive) {
          recorder?.start();
        }
      } catch (error) {
        console.error("MediaStream failed");
        setMediaStreamError(new DOMException("Mediastream failed", "TrackFailedError"));
      }
    }
  }, [isOnline, isVideoStreamingEnabled, data, recorder, streamingStoppedWhenOnline]);
};

export default useVideoRecording;

import React, { useEffect, useState } from "react";
import { TwilioAudioCallEvent, firebaseCollections, CallStatus } from "globals/enums";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";
import phoneicon from "assets/img/phoneicon.png";
import chatrequest from "assets/img/chatrequest.png";
import connector from "assets/img/newloader.gif";
import voliume from "assets/img/voliume.png";
import chatClse from "assets/img/chatClse.png";
import { handleError } from "helpers/sentry";
import { useAppDispatch, useAppSelector } from "store/store";
import { setTwilioAudioCallStatus } from "store/app.slice";

import { getDatabase, ref, onValue, update } from "firebase/database";
import { getSystemTime } from "helpers/common";

const MessageAudioBox: React.FC = () => {
  const dispatch = useAppDispatch();
  const [audioCallStatus, setAudioCallStatus] = useState<string>("");
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const participantGroupId = useAppSelector((state) => state.app.channelData.participant_group_id);
  const twilioCallStatus = useAppSelector((state) => state.app.twilioAudioCallStatus);

  const { attendee_id: attendeeId, session_id: sessionId } =
    useAppSelector((state) => state.app?.session) || {};

  const audioCallRef = ref(getDatabase(), `${firebaseCollections.AudioCall}/${sessionId}/`);

  const handleAudioCallStatus = async () => {
    try {
      await update(audioCallRef, {
        status: CallStatus.Requested,
        participant_group_id: participantGroupId,
        attendee_id: attendeeId,
        requested_timestamp: getSystemTime().toISOString()
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleCallRequest = async () => {
    if (navigator.onLine) {
      await handleAudioCallStatus();
      try {
        alertHelper(alertConfig).raiseAlert(AlertName.AudioCallRequested, dispatch);
        dispatch(
          setTwilioAudioCallStatus({ twilio_audio_call_status: TwilioAudioCallEvent.Requested })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  useEffect(() => {
    try {
      onValue(audioCallRef, (audioCallData) => {
        const status = audioCallData.val()?.status;
        setAudioCallStatus(status);
      });
    } catch (error) {
      handleError(error);
    }
  }, [audioCallRef]);

  const updateCallStatus = async (audioCallStatus: any) => {
    const audioCallData = {
      participant_group_id: participantGroupId,
      attendee_id: attendeeId
    };

    try {
      await update(audioCallRef, { ...audioCallData, ...audioCallStatus });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    switch (twilioCallStatus) {
      case TwilioAudioCallEvent.Requested:
        updateCallStatus({
          status: CallStatus.Requested,
          requested_timestamp: getSystemTime().toISOString()
        });
        break;
      case TwilioAudioCallEvent.Incoming:
        updateCallStatus({
          status: CallStatus.Connected,
          connected_timestamp: getSystemTime().toISOString()
        });
        break;
      case TwilioAudioCallEvent.Disconnect:
      case TwilioAudioCallEvent.Cancel:
      case TwilioAudioCallEvent.Ignore:
        updateCallStatus({
          status: CallStatus.Stale,
          disconnected_timestamp: getSystemTime().toISOString()
        });
        break;
      default:
        break;
    }
  }, [twilioCallStatus]);

  return (
    <div className="tvp-audio-chat-wrapper" aria-live="polite">
      {(audioCallStatus === CallStatus.Stale || audioCallStatus === CallStatus.Disconnected) && (
        <button
          className={`tvp-callBtn ${navigator.onLine ? "" : "tvp-disabled"}`}
          onClick={handleCallRequest}
          autoFocus={true}
        >
          <img src={phoneicon} alt="Audio call Icon" title="Make Audio call" />
        </button>
      )}
      {(audioCallStatus === CallStatus.Initiated || audioCallStatus === CallStatus.Requested) && (
        <div className="tvp-requested">
          <span className="tvp-chatStatus1">Call Requested</span>
          <img
            className="tvp-connecting"
            src={chatrequest}
            alt="Audio call connecting"
            title="Audio call connecting"
          />
          <img className="tvp-connecting" src={connector} alt="Icon" />
        </div>
      )}
      {audioCallStatus === CallStatus.Connected && (
        <div className="tvp-requested">
          <img className="tvp-connecting" src={voliume} alt="Icon" />
          <span className="tvp-chatStatus2"> Ongoing</span>
          <span className="tvp-chatStatus2"></span>

          <img className="tvp-connecting" src={chatClse} alt="Icon" />
          <div className="tvp-chatClse">
            <img className="tvp-connecting" src={chatClse} alt="Icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageAudioBox;

import { useAppSelector, RootState } from "store/store";
import { PauseProctorPayload } from "globals/interfaces";
import { FormattedMessage } from "react-intl";
import Header from "components/Header";
import FooterLogo from "components/FooterLogo";
import Connectivity from "assets/img/connectivity.png";

const PauseProctorScreen = () => {
  const { reason, note } = useAppSelector<PauseProctorPayload>(
    (state: RootState) => state.app.uiPauseScreen
  );
  return (
    <div id="basediv">
      <div
        className="tvp-flex tvp-flex-col tvp-justify-start tvp-h-auto tvp-max-w-2xl tvp-min-w-2xl 
    tvp-rounded-lg tvp-bg-white tvp-mx-4 tvp-my-0 tvp-px-2.5 tvp-py-4 tvp-overflow-x-hidden tvp-base"
      >
        <Header
          title={<FormattedMessage id="page.PauseProctorScreen.header" />}
          titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-red tvp-uppercase tvp-ml-2.5"}
        />
        <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
          <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
            <img src={Connectivity} alt="Proctoring paused" />
          </div>
          <h3 className="tvp-my-4 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-red">
            {reason || "Your proctoring session is paused."}
          </h3>
          <div className="tvp-text-center tvp-w-10/12 tvp-ml-auto tvp-mr-auto">
            <p>{note || "Please wait for few minutes."}</p>
          </div>
        </div>
        <FooterLogo />
      </div>
    </div>
  );
};

export default PauseProctorScreen;
